import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { localizedStrings } from '../../localizedStrings';

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  onSave: () => void;
  onDiscardChanges: () => void;
}

export const NotSavedArticleModal: React.FC<Props> = (props: Props) => (
  <Modal size="10rem" autoFocus={true} isOpen={props.isOpen}>
    <ModalHeader>{localizedStrings.article.draftModalHeader}</ModalHeader>
    <ModalBody>{localizedStrings.article.draftModalBody}</ModalBody>
    <ModalFooter className="justify-content-between">
      <div className="d-flex justify-content-start">
        <Button className="mr-1" color="primary" onClick={props.onSave}>
          {localizedStrings.global.save}
        </Button>

        <Button color="danger" onClick={props.onDiscardChanges}>
          {localizedStrings.global.doNotSave}
        </Button>
      </div>

      <div className="d-flex justify-content-end">
        <Button color="danger" onClick={props.onCancel}>
          {localizedStrings.global.cancel}
        </Button>
      </div>
    </ModalFooter>
  </Modal>
);
