import { Article, Category, Section, Ordered, WithID, Maybe } from '../Types';
import _ from 'lodash';

//this type ensures that if someone were to add keys in the future to Category that clash with the ones used here, a type error is shown so the developer is aware
type DisjointUnion<A, B> = Extract<keyof A, keyof B> extends never ? A & B : never;
export type CategoryTree = DisjointUnion<
  Category,
  {
    sections: DisjointUnion<Section, { articles: Article[] }>[];
    defaultSection: Article[];
  }
>;

export const sortByOrderIndex = (s1: Ordered, s2: Ordered): number => s1.orderIndex - s2.orderIndex;
export const sectionsInCategory = (categoryId: string, sections: Section[]): Section[] =>
  sections.filter((s) => s.category === categoryId);
export const articlesInSection = (sectionId: string, articles: Article[]): Article[] =>
  articles.filter((a) => a.section === sectionId);
export const articlesInCategory = (categoryId: string, articles: Article[]): Article[] =>
  articles.filter((a) => a.category === categoryId);
export const getByFId = <T extends WithID>(fId: string, things: T[]): Maybe<T> => things.find((t) => t.fId === fId);

//export const hasArticle = (categoryId : string, categories : Category[], articleId : string, articles : Article[]) : boolean => categories.some(c => )

export const getById = <T extends Article | Category | Section>(
  collection: T[],
  id?: string | null | undefined,
): Maybe<T> => collection.find((a) => a.fId === id);
export const getCategoryTree = (
  categoryId: string,
  categories: Category[],
  sections: Section[],
  articles: Article[],
): Maybe<CategoryTree> => {
  const category = getByFId(categoryId, categories);

  if (category === undefined) return;

  return {
    ...category,
    sections: sectionsInCategory(categoryId, sections)
      .map((s) => ({
        ...s,
        articles: articlesInSection(s.fId, articles).sort(sortByOrderIndex),
      }))
      .sort(sortByOrderIndex),

    defaultSection: articlesInCategory(categoryId, articles)
      .filter((a) => !a.section)
      .sort(sortByOrderIndex),
  };
};
