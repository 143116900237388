export default [
  { to: 'akut', title: 'AKUT' },
  { to: 'behandlingsriktlinjer', title: 'Behandlingsriktlinjer' },
  { to: 'lakemedel', title: 'Läkemedel' },
  { to: 'instruktioner', title: 'Instruktioner' },
  { to: 'info', title: 'Information' },
  { to: 'calculator', title: 'Dosberäknare' },
  { to: 'changelog', title: 'Nyheter och Uppdateringar' },
  { to: 'ekgtolkning', title: 'EKG tolkning' },
  { to: 'acib', title: 'ACIB' },
];
