import { toast, ToastOptions, ToastContent } from 'react-toastify';

export interface Orderable {
  data: () => {
    orderIndex: number;
  };
}

export const appSort = (left: Orderable, right: Orderable) => left.data().orderIndex - right.data().orderIndex;

export const toasts = (() => {
  const defaultOptions: ToastOptions = { type: toast.TYPE.DEFAULT, position: toast.POSITION.BOTTOM_CENTER };

  const notify = (content: ToastContent, options?: ToastOptions) => {
    toast(content, { ...defaultOptions, ...options });
  };

  return {
    info(message: string) {
      notify(message, { type: toast.TYPE.INFO });
    },
    success(message: string) {
      notify(message, { type: toast.TYPE.SUCCESS });
    },
    warning(message: string) {
      notify(message, { type: toast.TYPE.WARNING });
    },
    error(message: string) {
      notify(message, { type: toast.TYPE.ERROR });
    },
    default(message: string) {
      notify(message, { type: toast.TYPE.DEFAULT });
    },
  };
})();
