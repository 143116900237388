import React, { ReactElement, useEffect, useState } from 'react';
import { useCategories, useProjectConfig } from '../../ContextProviders/AppContext';
import { ErrorPage } from '../Error/ErrorPage';
import { Loading } from '../Loading/Loading';
import { PageWithSidebar } from '../PageTypes';
import { SettingsSidebar } from './SettingsSidebar';
import { localizedStrings } from '../../../localizedStrings';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from 'reactstrap';
import { useCollection } from '../../../Hooks';
import { CMSUser, StaffPassword, WithID } from '../../../Types';
import { useMonofunction } from '../../ContextProviders/Firebase';
import ChangePasswordForm from './ChangePasswordForm';
import { CMSUserData, UserRole } from '@eir/core';
import { CategoryLocker } from './CategoryLocker';
import './StaffAccounts.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/pro-solid-svg-icons';

type AccessRole = CMSUserData & WithID & Record<string, unknown>;

const Management = (): ReactElement => {
  const [accountIsSelected, setAccountIsSelected] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<AccessRole>({
    admin: '',
    categoryPermissions: [],
    createdOn: null,
    email: '',
    fId: localizedStrings.settings.selectAccount,
    role: UserRole.STAFF,
    displayName: localizedStrings.settings.selectAccount,
  });
  const [searchText, setSearchText] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [isInFocus, setIsInFocus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [usersList, setUsersList] = useState<JSX.Element[]>([]);

  const { docs: categories, error, loading } = useCategories();
  const { docs: CMSUsers, error: arError, loading: arLoading } = useCollection<CMSUser>('/cmsUser', {});
  const { docs: passwords, error: passError, loading: passLoading } = useCollection<StaffPassword>('/auth', {});

  const firebaseFunctions = useMonofunction();

  // spinner UI for one btn at a time
  const [buttonLoadingStates, setButtonLoadingStates] = useState<boolean[]>(Array(categories.length).fill(false));
  // disabled/unclickable for all btns
  const [btnsAreDisabled, setBtnsAreDisabled] = useState(false);

  const projectConfig = useProjectConfig();
  useEffect(() => {
    document.title = `${projectConfig.doc.name} | ${localizedStrings.settings.staffAccountMng}`;
  }, [projectConfig.doc.name]);

  useEffect(() => {
    const updatedUser = CMSUsers.find((ar) => ar.fId === selectedAccount.fId);
    if (updatedUser) {
      setSelectedAccount(updatedUser);
      setCurrentPassword(passwords.find((p) => p.fId === updatedUser.fId)?.password || '');
    }
  }, [CMSUsers, selectedAccount.fId, passwords]);

  useEffect(() => {
    const ul = CMSUsers.filter((ar) => {
      if (ar.role !== UserRole.STAFF) return false;
      if (ar.displayName.toLocaleLowerCase().includes(searchText.toLocaleLowerCase().trim())) return true;
      return false;
    }).map((ar) => (
      <DropdownItem
        key={`_${ar.displayName}`}
        style={{ margin: '0' }}
        onClick={() => {
          setSelectedAccount(ar);
          setAccountIsSelected(true);
        }}
      >
        {ar.displayName}
      </DropdownItem>
    ));

    setUsersList(ul);
  }, [CMSUsers, searchText]);

  useEffect(() => {
    setButtonLoadingStates(Array(selectedAccount.categoryPermissions.length).fill(false));
    setBtnsAreDisabled(false);
  }, [selectedAccount.categoryPermissions]);

  useEffect(() => {
    if (isInFocus) setIsOpen(true);
    else
      setTimeout(() => {
        setSearchText('');
        setIsOpen(false);
      }, 150);
    return () => {
      /* */
    };
  }, [isInFocus]);

  useEffect(() => {
    setButtonLoadingStates(Array(selectedAccount.categoryPermissions.length).fill(false));
    setBtnsAreDisabled(false);
  }, [selectedAccount.categoryPermissions]);

  if (error) return <ErrorPage error={error} />;
  if (arError) return <ErrorPage error={arError} />;
  if (passError) return <ErrorPage error={passError} />;
  if (loading) return <Loading waitingFor={localizedStrings.settings.categories} />;
  if (arLoading) return <Loading waitingFor={localizedStrings.settings.accessRoles} />;
  if (passLoading) return <Loading />;

  const setLoadingForButton = (id: number, state: boolean) => {
    const newLoadStates = [...buttonLoadingStates];
    newLoadStates[id] = state;
    setButtonLoadingStates(newLoadStates);
  };

  return (
    <>
      <Dropdown
        className="accounts-search shadow-sm"
        toggle={() => {
          /* */
        }}
      >
        <DropdownToggle tag={'div'}>
          <Input
            onFocus={() => setIsInFocus(true)}
            onBlurCapture={() => setIsInFocus(false)}
            onChange={({ target: { value } }) => setSearchText(value)}
            value={searchText}
            placeholder={selectedAccount.displayName}
            style={{ borderRadius: isOpen ? '0.4rem 0.4rem 0 0' : '0.4rem' }}
            className="account-select"
          />
          {isOpen ? '' : <FontAwesomeIcon icon={faSortDown} style={{ pointerEvents: 'none' }} />}
        </DropdownToggle>
        <DropdownMenu className="list shadow-sm" style={{ display: isOpen ? 'block' : 'none' }}>
          {usersList.length ? (
            usersList
          ) : (
            <DropdownItem className="empty-result">{localizedStrings.settings.emptyAccountSearch}</DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>

      {accountIsSelected ? (
        <>
          <div className="acc-mng-module shadow-sm">
            <ChangePasswordForm
              label={localizedStrings.settings.changeStaffPass}
              passType="STAFF"
              userId={selectedAccount.fId || ''}
              initialValue={currentPassword}
              key={currentPassword}
            />
          </div>

          <div className="acc-mng-module shadow-sm">
            <h5>{localizedStrings.settings.staffAccountCategories}</h5>
            <div className="categories">
              {categories.filter((c) => c.isProtected).length > 0
                ? categories
                    .filter((c) => c.isProtected)
                    .map((c, index) => (
                      <React.Fragment key={c.fId + '_0'}>
                        <div key={c.fId + '_1'} className="name">
                          {c.name}
                        </div>

                        <div key={c.fId + '_2'} className="toggle">
                          <CategoryLocker
                            key={c.fId + '_3'}
                            canRead={selectedAccount.categoryPermissions.includes(c.fId)}
                            isDisabled={btnsAreDisabled}
                            isLoading={buttonLoadingStates[index]}
                            onToggle={() => {
                              const updatedAccount = { ...selectedAccount };
                              const catPerms = [...selectedAccount.categoryPermissions];

                              const canRead = updatedAccount.categoryPermissions.includes(c.fId);
                              if (canRead)
                                updatedAccount['categoryPermissions'] = catPerms.filter((cat) => cat !== c.fId);
                              else updatedAccount['categoryPermissions'] = [...catPerms, c.fId];

                              setBtnsAreDisabled(true);
                              setLoadingForButton(index, true);

                              firebaseFunctions
                                .SetCategoryPermission({
                                  userId: updatedAccount.fId,
                                  category: c.fId,
                                  canRead: !canRead,
                                })
                                .catch((err) => {
                                  setBtnsAreDisabled(false);
                                  setLoadingForButton(index, false);
                                  console.log(err);
                                });
                            }}
                          />
                        </div>
                      </React.Fragment>
                    ))
                : localizedStrings.settings.staffAccountCategoriesPlaceholder}
            </div>
          </div>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export const StaffAccountManagement = (): ReactElement => {
  return (
    <PageWithSidebar>
      <SettingsSidebar />
      <main style={{ overflowY: 'auto', padding: '1rem' }}>
        <div className="settings-page">
          <h1>{localizedStrings.settings.staffAccountMng}</h1>
          <hr />
          <Management />
        </div>
      </main>
    </PageWithSidebar>
  );
};
