import { CSSProperties, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { localizedStrings } from '../../localizedStrings';
import { OfflineIndicator } from './OfllineIndicator';
import './Footer.scss';

const CloudQueue = ({ style = {} }: { style?: CSSProperties }) => (
  <svg {...{ style }} viewBox="0 0 25 25">
    <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM19 18H6c-2.21 0-4-1.79-4-4s1.79-4 4-4h.71C7.37 7.69 9.48 6 12 6c3.04 0 5.5 2.46 5.5 5.5v.5H19c1.66 0 3 1.34 3 3s-1.34 3-3 3z"></path>
  </svg>
);
const CloudOff = ({ style = {} }: { style?: CSSProperties }) => (
  <svg {...{ style }} viewBox="0 0 25 25">
    <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4c-1.48 0-2.85.43-4.01 1.17l1.46 1.46C10.21 6.23 11.08 6 12 6c3.04 0 5.5 2.46 5.5 5.5v.5H19c1.66 0 3 1.34 3 3 0 1.13-.64 2.11-1.56 2.62l1.45 1.45C23.16 18.16 24 16.68 24 15c0-2.64-2.05-4.78-4.65-4.96zM3 5.27l2.75 2.74C2.56 8.15 0 10.77 0 14c0 3.31 2.69 6 6 6h11.73l2 2L21 20.73 4.27 4 3 5.27zM7.73 10l8 8H6c-2.21 0-4-1.79-4-4s1.79-4 4-4h1.73z"></path>
  </svg>
);

export const Footer = (): ReactElement => {
  return (
    <footer className="d-flex p-1 pe-2 ps-2 footer-box">
      <div className="text-white">&copy; {new Date().getFullYear()} Softwerk AB. All rights reserved.</div>
      <div className="ms-auto pe-4">
        <OfflineIndicator
          online={<CloudQueue style={{ width: '1rem', height: '1rem', fill: 'white' }} />}
          offline={<CloudOff style={{ width: '1rem', height: '1rem', fill: 'white' }} />}
        />
        &nbsp;
      </div>
      <Link className="text-white pr-4 pe-4" to={`/privacy-policy`}>
        {localizedStrings.global.privacyPolicy}
      </Link>
      <Link className="text-white" to={`/term-of-use`}>
        {localizedStrings.global.termsOfUse}
      </Link>
    </footer>
  );
};
