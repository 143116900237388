import { NavLink } from 'react-router-dom';

import './Header.scss';
import {
  useCategories,
  useCategoriesDraft,
  useChiefCategories,
  useExternals,
  useIsDraft,
} from '../ContextProviders/AppContext';

import { useAuth } from '../ContextProviders/Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockAlt, faStar } from '@fortawesome/pro-solid-svg-icons';
import { useConcreteProject } from '../ContextProviders/ProjectContext';
import { Category, CustomNavigationItem } from '../../Types';
import { appCustomNavigation } from '../../navigation';
import { ReactElement } from 'react';
import { toasts } from '../../shared';
import { localizedStrings } from '../../localizedStrings';

interface NavItem {
  to: string;
  isProtected: boolean;
  isChief?: boolean;
  title: string;
}
const CategoryNavigation = (navLinks): ReactElement => {
  const auth = useAuth();
  const [draft] = useIsDraft();
  const project = useConcreteProject();
  const { docs: externals } = useExternals();
  const { docs: mainPub } = useCategories();
  const { docs: mainDraft } = useCategoriesDraft();
  const { docs: chief } = useChiefCategories();

  const mainRaw = draft ? mainDraft : mainPub;
  const main = auth.isAdmin
    ? mainRaw
    : mainRaw.filter((c) => auth.categoryPermissions.includes(c.fId) || c.isProtected === false);

  const orderIndexComparator = (c1: Category, c2: Category) => c1.orderIndex - c2.orderIndex;
  const restrictedProps = (n: NavItem) =>
    n.isProtected && !auth.isAdmin && !auth.isStaff
      ? {
          className: 'restricted',
          onClick: (e) => {
            toasts.warning(localizedStrings.auth.loginToSee);
            e.preventDefault();
          },
        }
      : {};

  const customNav = appCustomNavigation[project.id]?.default as CustomNavigationItem[];
  let navItems: NavItem[] = [];
  if (customNav) {
    const customNavItems = customNav.reduce((acc, cn) => {
      const category = main.find((c1) => c1.fId === cn.to);
      const external = externals.find((e) => e.fId === cn.to);

      if (category)
        return [...acc, { to: `/category/${cn.to}`, isProtected: category.isProtected || false, title: cn.title }];
      if (external) return [...acc, { to: `/external/${cn.to}`, isProtected: cn.lockExternal, title: cn.title }];

      return acc;
    }, [] as NavItem[]);
    navItems = [...navItems, ...customNavItems];
  } else {
    //Else, just use categories, changelog and externals
    const mainNavItems = main
      .sort(orderIndexComparator)
      .map((c) => ({ to: `/category/${c.fId}`, isProtected: c.isProtected, title: c.name }));
    const externaNavItems = externals.map((c) => ({ to: `/externals/${c.fId}`, isProtected: false, title: c.fId }));
    navItems = [...navItems, ...mainNavItems, ...externaNavItems]; //Changelog missing
  }

  navItems = [
    ...navItems,
    ...chief
      .sort(orderIndexComparator)
      .map((c) => ({ to: `/category/${c.fId}`, isProtected: true, isChief: true, title: c.name })),
  ];

  return (
    <nav className="header-bottom-row">
      <ol>
        {navItems.map((c, i) => (
          <li key={c.title}>
            <NavLink {...restrictedProps(c)} activeClassName="active" to={`${c.to}`} title={c.title}>
              {c.title}
              {c.isProtected && (
                <FontAwesomeIcon color="white" className="nav-link-icon" icon={c.isChief ? faStar : faLockAlt} />
              )}
              <svg height="8" width="14" className="triangle-indicator">
                <polygon points="7,0 14,8 0,8" className="triangle" />
              </svg>
            </NavLink>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default CategoryNavigation;
