import FE from 'froala-editor';

(function (FroalaEditor) {
  FroalaEditor.DEFAULTS = Object.assign(FroalaEditor.DEFAULTS, {
    myOption: false,
  });

  Object.assign(FE.DEFAULTS, {
    listAdvancedTypes: true,
  });

  FE.PLUGINS.checkBoxs = function (editor) {
    const $ = editor.$;

    function addCheckbox(param) {
      const blocks = editor.selection.blocks(true);

      for (let i = 0; i < blocks.length; i++) {
        if (blocks[i].tagName === 'FIELDSET') {
          if (param !== undefined) {
            $(blocks[i]).removeAttr('class');
            $(blocks[i]).addClass(param);
          } else {
            editor.html.insert(`<br/><input type="checkbox">&nbsp;`);
          }
        } else {
          editor.html.insert(`<fieldset class="${param}"><input type="checkbox">&nbsp;</fieldset>`);
        }
      }
    }
    function refresh($btn) {
      const $el = $(editor.selection.element());
      let input = $el.get(0);
      if (input && input.tagName.toLowerCase() === 'fieldset' && editor.el.contains(input.parentNode)) {
        $btn.addClass('fr-active');
      }
    }

    return {
      addCheckbox,
      refresh,
    };
  };
})(FE);

FE.DefineIconTemplate(
  'checklistIcon',
  `<svg width=45% height=45% xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M211.8 339.8C200.9 350.7 183.1 350.7 172.2 339.8L108.2 275.8C97.27 264.9 97.27 247.1 108.2 236.2C119.1 225.3 136.9 225.3 147.8 236.2L192 280.4L300.2 172.2C311.1 161.3 328.9 161.3 339.8 172.2C350.7 183.1 350.7 200.9 339.8 211.8L211.8 339.8zM0 96C0 60.65 28.65 32 64 32H384C419.3 32 448 60.65 448 96V416C448 451.3 419.3 480 384 480H64C28.65 480 0 451.3 0 416V96zM48 96V416C48 424.8 55.16 432 64 432H384C392.8 432 400 424.8 400 416V96C400 87.16 392.8 80 384 80H64C55.16 80 48 87.16 48 96z"/></svg>
`,
);
FE.DefineIcon('checkBoxIcon', { NAME: 'checklist', template: 'checklistIcon' });
FE.RegisterCommand('checkbox', {
  title: 'Checkbox',
  type: 'button',
  icon: 'checkBoxIcon',
  focus: false,
  undo: false,
  refreshAfterCallback: true,
  hasOptions: function hasOptions() {
    return this.opts.listAdvancedTypes;
  },
  options: {
    'small-checkbox': 'Small',
    'medium-checkbox': 'Medium',
    'big-checkbox': 'Large',
  },
  refresh: function refresh($btn) {
    this.checkBoxs.refresh($btn);
  },
  callback: function callback(cmd, param) {
    this.checkBoxs.addCheckbox(param);
  },
  plugin: 'checkBoxs',
});
