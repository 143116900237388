import { ReactElement, useState, useEffect } from 'react';
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { localizedStrings } from '../../localizedStrings';

export const PublishMessageModal = ({
  onPublish,
  onCancel,
  isOpen,
}: {
  onPublish: (msg?: string) => void;
  onCancel: () => void;
  isOpen: boolean;
}): ReactElement => {
  const [hasMessage, setHasMessage] = useState(false);
  const [publishMessage, setPublishMessage] = useState('');

  useEffect(() => {
    setHasMessage(false);
    setPublishMessage('');
  }, [isOpen]);

  return (
    <Modal size="10rem" autoFocus={true} isOpen={isOpen}>
      <ModalHeader style={{ paddingBottom: '0.5rem' }}>{localizedStrings.publish.publish}</ModalHeader>
      <ModalBody style={{ paddingTop: '1rem', paddingLeft: '1rem', paddingBottom: '1rem' }}>
        <Label style={{ fontWeight: 300, margin: 0 }}>
          <Input
            type="checkbox"
            checked={hasMessage}
            onChange={({ target: { checked } }) => {
              setHasMessage(checked);
            }}
          />
          &nbsp;{localizedStrings.publish.setPublishMessage}
        </Label>
        {hasMessage && (
          <Input
            type="text"
            placeholder={localizedStrings.publish.setPublishMessage}
            onChange={({ target: { value } }) => {
              setPublishMessage(value);
            }}
            style={{ marginTop: '1rem' }}
          />
        )}
      </ModalBody>
      <ModalFooter style={{ paddingTop: '1rem' }} className="justify-content-between">
        <div className="d-flex justify-content-start">
          <Button
            className="mr-1"
            color="primary"
            onClick={() => onPublish(publishMessage !== '' ? publishMessage : undefined)}
          >
            {localizedStrings.publish.publish}
          </Button>
          <Button color="danger" onClick={onCancel}>
            {localizedStrings.global.cancel}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
