import { faLockAlt, faCheck, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { ReactElement } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const CategoryLocker = ({
  canRead,
  isLoading,
  isDisabled,
  onToggle,
}: {
  canRead: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  onToggle: () => void;
}): ReactElement => {
  return (
    <Button
      color={isLoading ? 'info' : canRead ? 'success' : 'danger'}
      outline={true}
      onClick={onToggle}
      className="mb-2 category-locker"
      disabled={isDisabled}
    >
      <FontAwesomeIcon icon={isLoading ? faSpinner : canRead ? faCheck : faLockAlt} />
    </Button>
  );
};
