import { faEnvelope, faPlus, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, FormFeedback, Input, InputGroup, Row } from 'reactstrap';
import { configActions } from '../../../Hooks/DatabaseActions';
import { localizedStrings } from '../../../localizedStrings';
import { useProjectConfig, useProjectConfigDraft } from '../../ContextProviders/AppContext';
import { useFirestore } from '../../ContextProviders/Firebase';
import { useConcreteProject } from '../../ContextProviders/ProjectContext';
import { ErrorPage } from '../Error/ErrorPage';
import { Loading } from '../Loading/Loading';
import { PageWithSidebar } from '../PageTypes';
import { SettingsSidebar } from './SettingsSidebar';
import './SettingsPage.scss';
import './EmailPage.scss';
import { toasts } from '../../../shared';
import { IconButton } from '../../Buttons/Buttons';

export const EmailManagement = (): ReactElement => {
  const { doc: config, error, loading } = useProjectConfigDraft();
  const firebase = useFirestore();
  const project = useConcreteProject();
  const { update: updateConfig } = configActions(firebase, project.id);
  const [newEmail, setNewEmail] = useState('');
  const [invalidEmail, setInvalidEmail] = useState<boolean>(false);
  const [isDisable, setIsDisable] = useState<boolean>(true);

  const projectConfig = useProjectConfig();
  useEffect(() => {
    document.title = `${projectConfig.doc.name} | ${localizedStrings.settings.email}`;
  }, [projectConfig.doc?.name]);

  if (error) return <ErrorPage error={error} />;
  if (loading) return <Loading waitingFor="Project Configuration" />;

  const deleteEmail = async (email: string) => {
    try {
      await updateConfig({ feedbackEmails: config.feedbackEmails.filter((cemail) => cemail !== email) });
      toasts.success(localizedStrings.settings.updateOk);
    } catch (e) {
      toasts.error(localizedStrings.settings.updateFailed);
    }
  };

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  const addEmail = async (email: string) => {
    if (validateEmail(email)) {
      setInvalidEmail(false);
      setIsDisable(false);
      try {
        await updateConfig({ feedbackEmails: [...config.feedbackEmails, email] });
        setNewEmail('');
        toasts.success(localizedStrings.settings.updateOk);
      } catch (error) {
        toast.error(localizedStrings.settings.updateFailed);
      }
    } else {
      setInvalidEmail(true);
      setIsDisable(true);
    }
  };

  const _onChange: React.ChangeEventHandler<HTMLInputElement> | undefined = (e) => {
    setNewEmail(e.target.value);
    setInvalidEmail(!validateEmail(e.target.value));
    setIsDisable(!validateEmail(e.target.value));
  };

  const _onBlur: React.FocusEventHandler<HTMLInputElement> | undefined = () => {
    if (newEmail.trim().length === 0) setInvalidEmail(false);
  };

  return (
    <PageWithSidebar>
      <SettingsSidebar />
      <main className="padded-container">
        <div className="settings-page email-page">
          <h1>{localizedStrings.settings.email}</h1>
          <p style={{ color: 'var(--grayscale-2)' }}>{localizedStrings.settings.addEmailsHint}</p>
          <hr />
          <Col sm={12} lg={6}>
            <Row>
              <InputGroup style={{ position: 'relative', width: '28rem' }}>
                <Input
                  type="email"
                  value={newEmail}
                  onChange={_onChange}
                  invalid={invalidEmail}
                  onBlur={_onBlur}
                  placeholder="example@domain.com"
                  onKeyDownCapture={({ key }) => {
                    return key === 'Enter' && !invalidEmail ? void addEmail(newEmail) : undefined;
                  }}
                  style={{ maxWidth: '25rem' }}
                />
                <IconButton
                  onClick={() => {
                    void addEmail(newEmail);
                    setIsDisable(true);
                  }}
                  disabled={isDisable}
                  style={{ margin: '0 0 0 0.4rem', position: 'relative' }}
                  color="primary"
                  icon={faPlus}
                />
                <FormFeedback style={{ position: 'absolute', bottom: '-1.5rem', left: '0.5rem' }}>
                  {localizedStrings.settings.emailError}
                </FormFeedback>
              </InputGroup>
            </Row>
            <br />
            <div className="email-list acc-mng-module shadow-sm">
              {config.feedbackEmails.map((email, index) => (
                <Row className="align-items-center text-muted" key={index}>
                  <div className="col-md-8 col d-flex align-items-center">
                    <FontAwesomeIcon icon={faEnvelope} className="pe-1 me-2" style={{ fontSize: '1.2rem' }} />
                    <div className="text-dark" key={index}>
                      {email}
                    </div>
                  </div>
                  <div className="col-md-4 col text-end">
                    <Button color="danger" outline={true} onClick={() => void deleteEmail(email)}>
                      <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                    </Button>
                  </div>
                </Row>
              ))}
            </div>
          </Col>
          <Col sm={12} lg={6}></Col>
        </div>
      </main>
    </PageWithSidebar>
  );
};
