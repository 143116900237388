import { ReactElement, useEffect } from 'react';
import { localizedStrings } from '../../../localizedStrings';
import { useProjectConfig } from '../../ContextProviders/AppContext';

export const ErrorPage = ({ error }: { error: Error }): ReactElement => {
  const projectConfig = useProjectConfig();
  useEffect(() => {
    document.title = `${projectConfig.doc.name} | ${localizedStrings.error.error}`;
  }, [projectConfig.doc?.name]);

  return <code>{error.toString()}</code>;
};
