import { createContext, ReactElement, ReactNode, useContext, useRef, useState } from 'react';

interface ModalContextProps {
  spawn: (id: string, element: ReactElement) => void;
  subscribe: SubscribeFunction;
}
type SubscribeFunction = (callback: Subscriber) => void;
type Subscriber = (id: string, element: ReactElement) => void;
const dummySubscriber = (id: string, element: ReactElement) => {
  /**/
};
const dummySubscribeFunction = (sub: Subscriber) => {
  /**/
};
const ModalContext = createContext<ModalContextProps>({
  spawn: () => {
    /**/
  },
  subscribe: dummySubscribeFunction,
});

export const ModalContextProvider = ({ children }: { children?: ReactNode }): ReactElement => {
  const subscriber = useRef<Subscriber>(dummySubscriber);

  return (
    <ModalContext.Provider
      value={{
        spawn: (id: string, element: ReactElement) => {
          subscriber.current(id, element);
        },
        subscribe: (sub: Subscriber) => {
          subscriber.current = sub;
        },
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const ModalContextDisplayer = () => {
  const [elements, setElements] = useState<Record<string, ReactElement>>({});
  useContext(ModalContext).subscribe((id: string, component: ReactElement) => {
    setElements((els) => ({ ...els, [id]: component }));
  });
  return <>{Object.values(elements).map((Mod, i) => ({ ...Mod, key: i }))}</>;
};

export const useModalSpawn = () => useContext(ModalContext).spawn;
