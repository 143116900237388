import { FirebaseApp, FirebaseOptions, initializeApp } from 'firebase/app';
import { enableIndexedDbPersistence, Firestore, getFirestore, initializeFirestore } from 'firebase/firestore';
import { FirebaseStorage, getStorage } from 'firebase/storage';
import useLocalStorage from 'react-use-localstorage';
import { createContext, ReactElement, ReactNode, useContext, useEffect, useState } from 'react';
import { Loading } from '../Pages/Loading/Loading';
import { ErrorPage } from '../Pages/Error/ErrorPage';
import { isEqual } from 'lodash/fp';
import { httpsCallable, getFunctions } from 'firebase/functions';
import { MonofunctionClient } from '@eir/core/src/monofunction-client';
interface FirebaseContextProps {
  firebase: FirebaseApp;
  firestore: Firestore;
}

type MonofunctionType = ({ method, params }: { method: string; params: unknown }) => Promise<{ data: unknown }>;
const monofunctionProxyFactory = (monofunction: MonofunctionType) => {
  return new Proxy(
    {},
    {
      get: (_, prop) => (params) => monofunction({ method: prop.toString(), params }).then(({ data }) => data),
    },
  ) as MonofunctionClient;
};
export const FirebaseContext = createContext<FirebaseContextProps>({} as FirebaseContextProps);

export const useFirestore = (): Firestore => useContext(FirebaseContext).firestore;
export const useStorage = (): FirebaseStorage => getStorage(useContext(FirebaseContext).firebase);
export const useFirebase = (): FirebaseApp => useContext(FirebaseContext).firebase;
export const useMonofunction = () => {
  const fb = useContext(FirebaseContext).firebase;
  return monofunctionProxyFactory(httpsCallable(getFunctions(fb, 'europe-west3'), 'Monofunction'));
};

export function FirebaseProvider({ children }: { children?: ReactNode }): ReactElement {
  const [error, setError] = useState<Error>();
  const [firebase, setFirebase] = useState<FirebaseApp>();
  const [firestore, setFirestore] = useState<Firestore>();
  const [configJson, setConfigJson] = useLocalStorage('firebaseConfig', 'null');

  useEffect(() => {
    const firebaseConfig = JSON.parse(configJson);
    if (firebaseConfig !== null) {
      try {
        const fb = initializeApp(firebaseConfig, `firebase-${firebaseConfig.projectId}`);
        const fs =
          firebaseConfig.projectId === 'infosynk-ambulans-halland'
            ? initializeFirestore(fb, { experimentalForceLongPolling: true })
            : getFirestore(fb);

        setFirebase(fb);
        setFirestore(fs);
        enableIndexedDbPersistence(fs).catch((err) => {
          if (err.code === 'failed-precondition') {
            console.log('Could not synchronize data between open tabs; multi-tab persistence is disabled');
          } else if (err.code === 'unimplemented') {
            console.log('Could not enable persistence; browser is unsupported');
          }
        });
        //Enabling this MASSIVELY speeds up load
      } catch (e) {
        /* */
      }
    }
  }, [configJson]);

  useEffect(() => {
    fetch('/__/firebase/init.json')
      .then((c) => c.json() as FirebaseOptions)
      .then((fbo) => {
        const firebaseConfig = JSON.parse(configJson);
        if (!isEqual(firebaseConfig, fbo)) {
          setConfigJson(JSON.stringify(fbo));
        }
      })
      .catch((e) => {
        setError(e);
      });
    // eslint-disable-next-line
  }, [configJson]); //Not ideal, but not sure how to solve

  if (error && !firebase) {
    return <ErrorPage error={error} />;
  }
  if (!firebase || !firestore) {
    return <Loading waitingFor="Database" />;
  }

  return <FirebaseContext.Provider value={{ firebase, firestore }}>{children}</FirebaseContext.Provider>;
}
