import { ReactElement, useEffect } from 'react';
import { localizedStrings } from '../../../localizedStrings';
import { useProjectConfig } from '../../ContextProviders/AppContext';
import { useAuth } from '../../ContextProviders/Auth';
import { Loading } from '../Loading/Loading';
import { PageWithSidebar } from '../PageTypes';
import ChangePasswordForm from './ChangePasswordForm';
import { SettingsSidebar } from './SettingsSidebar';

export const AccountManagement = (): ReactElement => {
  const auth = useAuth();
  const projectConfig = useProjectConfig();

  useEffect(() => {
    document.title = `${projectConfig.doc.name} | ${localizedStrings.settings.accountMng}`;
  }, [projectConfig.doc?.name]);

  if (projectConfig.loading) return <Loading waitingFor="Project Config" />;

  return (
    <PageWithSidebar>
      <SettingsSidebar />
      <main className="padded-container">
        <div className="settings-page">
          <h1>{localizedStrings.settings.accountMng}</h1>
          <hr />
          <div className="acc-mng-module shadow-sm">
            <ChangePasswordForm
              label={localizedStrings.settings.changeAdminPass}
              passType="ADMIN"
              userId={auth.user?.uid || ''}
            />
          </div>
        </div>
      </main>
    </PageWithSidebar>
  );
};
