import FroalaEditor from 'froala-editor';
import { localizedStrings } from '../localizedStrings';
import { toasts } from '../shared';

async function copyURL(url) {
  try {
    void navigator.clipboard.writeText(url).then(() => {
      toasts.info(localizedStrings.global.linkCopied);
    });
  } catch (err) {
    console.error('Failed to copy: ', err);
  }
}
FroalaEditor.PLUGINS.click = function (editor) {
  function _init() {
    editor.events.on('click', function (e) {
      const anchorAttribute = e.target.getAttribute('data-anchor-link');
      if (anchorAttribute) copyURL(anchorAttribute);
    });
  }
  return {
    _init,
  };
};
FroalaEditor.DefineIconTemplate(
  'anchorIcon',
  '<svg xmlns="http://www.w3.org/2000/svg" width="40%" height="40%" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.1.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M352 176C369.7 176 384 190.3 384 208C384 225.7 369.7 240 352 240H320V448H368C421 448 464 405 464 352V345.9L456.1 352.1C447.6 362.3 432.4 362.3 423 352.1C413.7 343.6 413.7 328.4 423 319L479 263C488.4 253.7 503.6 253.7 512.1 263L568.1 319C578.3 328.4 578.3 343.6 568.1 352.1C559.6 362.3 544.4 362.3 535 352.1L528 345.9V352C528 440.4 456.4 512 368 512H208C119.6 512 48 440.4 48 352V345.9L40.97 352.1C31.6 362.3 16.4 362.3 7.029 352.1C-2.343 343.6-2.343 328.4 7.029 319L63.03 263C72.4 253.7 87.6 253.7 96.97 263L152.1 319C162.3 328.4 162.3 343.6 152.1 352.1C143.6 362.3 128.4 362.3 119 352.1L112 345.9V352C112 405 154.1 448 208 448H256V240H224C206.3 240 192 225.7 192 208C192 190.3 206.3 176 224 176H234.9C209 158.8 192 129.4 192 96C192 42.98 234.1 0 288 0C341 0 384 42.98 384 96C384 129.4 366.1 158.8 341.1 176H352zM288 128C305.7 128 320 113.7 320 96C320 78.33 305.7 64 288 64C270.3 64 256 78.33 256 96C256 113.7 270.3 128 288 128z"/></svg>',
);
FroalaEditor.DefineIcon('anchorIcon', { template: 'anchorIcon' });
FroalaEditor.RegisterCommand('anchorLink', {
  title: localizedStrings.global.insertAnchorlink,
  icon: 'anchorIcon',
  undo: false,
  focus: false,
  plugin: 'click',
  callback: function () {
    const id = Math.random().toString(36).slice(2);
    const currenUrl = new URL(window.location.href).pathname;
    const href = `/articles${currenUrl.substring(currenUrl.lastIndexOf('/'))}#${id}`;
    this.html.insert(` <a id="${id}" data-anchor-link="${href}" style="cursor: pointer;">⚓</a> `);
    copyURL(href);
  },
});
