import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { localizedStrings } from '../../../localizedStrings';
import './SettingsSidebar.scss';

export const SettingsSidebar = (): ReactElement => {
  const [isToggled, setIsToggled] = useState(true);

  return (
    <>
      <aside className={isToggled ? 'sidebar' : 'sidebar closed'}>
        <ul className={'settings-sidebar'}>
          <li>
            <NavLink activeClassName="active" to="/settings/account">
              {localizedStrings.settings.accountMng}
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to="/settings/staff">
              {localizedStrings.settings.staffAccountMng}
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to="/settings/access">
              {localizedStrings.settings.access}
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to="/settings/email">
              {localizedStrings.settings.email}
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to="/settings/project">
              {localizedStrings.settings.projectSettings}
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to="/settings/handbook">
              {localizedStrings.settings.handbook}
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to="/settings/export">
              <span> {localizedStrings.settings.export.title}</span>
            </NavLink>
          </li>
        </ul>
      </aside>
      <button className="collapse-button" onClick={() => setIsToggled(!isToggled)}>
        <FontAwesomeIcon icon={isToggled ? faAngleLeft : faAngleRight} />
      </button>
    </>
  );
};
