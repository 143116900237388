export const defaultEditorConfig = {
  key: 'TEB9iA6F4B3H5D3J4c1JWSDBCQJ1ZGDa1F1c1JXDAAOZWJhB3D3C10D7C3B4F6F3D3I3==',
  language: 'sv',
  htmlAllowedTags: ['.*'],
  htmlAllowedAttrs: ['.*'],
  charCounterCount: false,
  toolbarButtons: [
    'fullscreen',
    'fontFamily',
    'fontSize',
    'bold',
    'italic',
    'underline',
    'strikeThrough',
    'formatUL',
    'formatOL',
    'checkbox',
    '|',
    'textColor',
    'backgroundColor',
    'paragraphStyle',
    'paragraphFormat',
    'align',
    'outdent',
    'indent',
    'lineHeight',
    '|',
    'insertLink',
    'anchorLink',
    'insertImage',
    'insertVideo',
    'insertFile',
    'insertTable',
    'iframeinsert',
    '|',
    'quote',
    'insertHR',
    'undo',
    'redo',
    'clearFormatting',
    'selectAll',
    'html',
    '|',
    'print',
    'help',
  ],
  imageEditButtons: [
    'imageReplace',
    'imageAlign',
    'imageRemove',
    '|',
    'imageLink',
    'linkOpen',
    'linkEdit',
    'linkRemove',
    '-',
    'imageDisplay',
    'imageStyle',
    'imageAlt',
    'imageSize',
  ],
  videoInsertButtons: ['videoByURL', 'videoEmbed'],
  fontSize: ['6', '8', '10', '12', '14', '16', '18', '20', '22', '24', '30', '36', '48', '60', '72', '96'],
  entities:
    '&lt;&gt;&iexcl;&cent;&pound;&curren;&yen;&brvbar;&sect;&uml;&copy;&ordf;&laquo;&not;&shy;&reg;&macr;&deg;&sup2;' +
    '&sup3;&acute;&micro;&para;&middot;&cedil;&sup1;&ordm;&raquo;&frac14;&frac12;&frac34;&iquest;&Yacute;&THORN;' +
    '&szlig;&Alpha;&Beta;&Gamma;&Delta;&Epsilon;&Zeta;&Eta;&Theta;&Iota;&Kappa;&Lambda;&Mu;&Nu;&Xi;&Omicron;&Pi;&Rho;' +
    '&Sigma;&Tau;&Upsilon;&Phi;&Chi;&Psi;&Omega;&alpha;&beta;&gamma;&delta;&epsilon;&zeta;&eta;&theta;&iota;&kappa;' +
    '&lambda;&mu;&nu;&xi;&omicron;&pi;&rho;&sigmaf;&sigma;&tau;&upsilon;&phi;&chi;&psi;&omega;&thetasym;&upsih;&piv;' +
    '&ensp;&emsp;&thinsp;&lsquo;&rsquo;&sbquo;&ldquo;&rdquo;&bdquo;&dagger;&Dagger;&bull;&hellip;&permil;&lsaquo;' +
    '&rsaquo;&oline;&frasl;&euro;&image;&weierp;&real;&trade;&alefsym;&larr;&uarr;&rarr;&darr;&harr;&crarr;&lArr;' +
    '&uArr;&rArr;&dArr;&hArr;&forall;&part;&exist;&empty;&nabla;&isin;&notin;&ni;&prod;&sum;&minus;&lowast;&radic;' +
    '&prop;&infin;&ang;&and;&or;&cap;&cup;&int;&there4;&sim;&cong;&asymp;&ne;&equiv;&le;&ge;&sub;&sup;&nsub;&sube;' +
    '&supe;&oplus;&otimes;&perp;&sdot;&lceil;&rceil;&lfloor;&rfloor;&lang;&rang;&loz;&spades;&clubs;&hearts;&diams;',
  //Increased z-index for the popups to display inside body-content class
  zIndex: 5,
  attribution: false,
};
