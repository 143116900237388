import './ExternalPage.scss';
import { ReactElement, useEffect } from 'react';
import { PlatformContentFrame } from '../../PlatformContentFrame/PlatformContentFrame';
import { useExternal, useProjectConfig } from '../../ContextProviders/AppContext';
import { useParams } from 'react-router-dom';
import { localizedStrings } from '../../../localizedStrings';

interface ExternalParams {
  externalId: string;
}
export const ExternalPage = (): ReactElement => {
  const { externalId } = useParams<ExternalParams>();
  const external = useExternal(externalId);

  const projectConfig = useProjectConfig();
  useEffect(() => {
    document.title = `${projectConfig.doc.name} | ${localizedStrings.external.externalContent}`;
  }, [projectConfig.doc?.name]);

  if (!external) return <></>;

  return <PlatformContentFrame body={external.content} />;
};
