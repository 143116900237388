export default [
  { to: 'app', title: 'Din app' },
  { to: 'funktionalitet', title: 'Funktionalitet' },
  { to: 'kunder', title: 'Kunder' },
  { to: 'kalkylator', title: 'Doskalkylator' },
  { to: 'ekg', title: 'EKG' },
  { to: 'trafik', title: 'Trafikinfo' },
  { to: 'admin', title: 'Administratörsflik' },
  { to: 'changelog', title: 'Ändringslogg' },
];
