import React, { ReactElement, useEffect, useState } from 'react';
import { useCategoriesDraft, useProjectConfig } from '../../ContextProviders/AppContext';
import { ErrorPage } from '../Error/ErrorPage';
import { Loading } from '../Loading/Loading';
import { PageWithSidebar } from '../PageTypes';
import { SettingsSidebar } from './SettingsSidebar';
import { localizedStrings } from '../../../localizedStrings';
import { useMonofunction } from '../../ContextProviders/Firebase';
import { CategoryLocker } from './CategoryLocker';
import { sortByOrderIndex } from '../../../util/DataHelpers';

export const AccessManagement = (): ReactElement => {
  const { docs: categories, error, loading } = useCategoriesDraft();

  const firebaseFunctions = useMonofunction();

  const [buttonLoadingStates, setButtonLoadingStates] = useState<boolean[]>(Array(categories.length).fill(false));
  const [btnsAreDisabled, setBtnsAreDisabled] = useState(false);

  const projectConfig = useProjectConfig();
  useEffect(() => {
    document.title = `${projectConfig.doc.name} | ${localizedStrings.settings.staffAccountMng}`;
  }, [projectConfig.doc.name]);

  useEffect(() => {
    setButtonLoadingStates(Array(categories.length).fill(false));
    setBtnsAreDisabled(false);
  }, [categories]);

  if (error) return <ErrorPage error={error} />;
  if (loading) return <Loading waitingFor={localizedStrings.settings.categories} />;

  const setLoadingForButton = (id: number, state: boolean) => {
    const newLoadStates = [...buttonLoadingStates];
    newLoadStates[id] = state;
    setButtonLoadingStates(newLoadStates);
  };

  return (
    <PageWithSidebar>
      <SettingsSidebar />
      <main style={{ overflowY: 'auto', padding: '1rem' }}>
        <div className="settings-page">
          <h1>{localizedStrings.settings.access}</h1>
          <p style={{ color: 'var(--grayscale-2)' }}>{localizedStrings.settings.categoryAccessHint}</p>
          <hr />
          <div className="categories shadow-sm acc-mng-module">
            {categories.sort(sortByOrderIndex).map((c, index) => (
              <React.Fragment key={c.fId + '_0'}>
                <div key={c.fId + '_1'} className="name">
                  {c.name}
                </div>

                <div key={c.fId + '_2'} className="toggle">
                  <CategoryLocker
                    key={c.fId + '_3'}
                    canRead={!c.isProtected}
                    isDisabled={btnsAreDisabled}
                    isLoading={buttonLoadingStates[index]}
                    onToggle={() => {
                      setBtnsAreDisabled(true);
                      setLoadingForButton(index, true);
                      firebaseFunctions
                        .SetCategoryProtection({
                          category: c.fId,
                          isProtected: !c.isProtected,
                        })
                        .catch((err) => {
                          setBtnsAreDisabled(false);
                          setLoadingForButton(index, false);
                          console.log(err);
                        });
                    }}
                  />
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </main>
    </PageWithSidebar>
  );
};
