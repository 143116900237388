import FE from 'froala-editor';
import { localizedStrings } from '../localizedStrings';

// Define popup template.
FE.POPUP_TEMPLATES['iframePlugin.popup'] = '[_CUSTOM_LAYER_]';

// Define popup buttons.
Object.assign(FE.DEFAULTS, {
  linkAutoPrefix: 'http://',
});

// The custom popup is defined inside a plugin (new or existing).
FE.PLUGINS.iframePlugin = function (editor) {
  // Create custom popup.
  function initPopup() {
    // Load popup template.
    const template = {
      custom_layer: `
      <div class="fr-link-insert-layer fr-layer fr-active" id="fr-my-layer-${editor.id}">
        <div class="fr-input-line">
          <input id="fr-my-layer-url-${
            editor.id
          }" name="href" type="text" class="fr-link-attr" placeholder="${editor.language.translate('URL')}">
          <div class="fr-video-group" style="display: flex">
            <div class="fr-input-line me-1">
              <input id="fr-my-layer-width-${
                editor.id
              }" name="width" type="text" class="fr-link-attr" placeholder="${editor.language.translate('Width')}">
              <div class="tooltip-size-info">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill size-info-icon" viewBox="0 0 16 16">
                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                </svg>
                <span class="tooltiptext-size">${localizedStrings.global.iframTooltip} 100%</span>
              </div>
            </div>
            <div class="fr-input-line ms-1">
              <input id="fr-my-layer-height-${
                editor.id
              }" name="height" type="text" class="fr-link-attr" placeholder="${editor.language.translate('Height')}">
              <div class="tooltip-size-info">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill size-info-icon" viewBox="0 0 16 16">
                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                </svg>
                <span class="tooltiptext-size">${localizedStrings.global.iframTooltip} 500px</span>
              </div>
            </div>
          </div>
          <div class="fr-action-buttons">
            <button class="fr-command fr-submit" role="button" data-cmd="iframeLinkInsert" href="#" type="button">${editor.language.translate(
              'Insert',
            )}</button>
          </div>
        </div>
      </div>
      `,
    };
    // Create popup.
    const $popup = editor.popups.create('iframePlugin.popup', template);

    return $popup;
  }

  // Show the popup
  function showPopup() {
    let $popup = editor.popups.get('iframePlugin.popup');

    if (!$popup) $popup = initPopup();

    editor.popups.setContainer('iframePlugin.popup', editor.$tb);

    const $btn = editor.$tb.find('.fr-command[data-cmd="iframeinsert"]');

    const left = $btn.offset().left + $btn.outerWidth() / 2;
    const top = $btn.offset().top + (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10);

    editor.popups.show('iframePlugin.popup', left, top, $btn.outerHeight());
  }

  // Hide the custom popup.
  function hidePopup() {
    editor.popups.hide('iframePlugin.popup');
  }

  function insertLink() {
    const $popup = editor.popups.get('iframePlugin.popup');
    const text_inputs = $popup.find('input.fr-link-attr[type="text"]');

    let href = (text_inputs.filter('[name="href"]').val() || '').trim();
    let width = (text_inputs.filter('[name="width"]').val() || '').trim();
    let height = (text_inputs.filter('[name="height"]').val() || '').trim();
    if (href === '') {
      hidePopup();
      return;
    }

    // Check if is local path.
    const local_path = /^([A-Za-z]:(\\){1,2}|[A-Za-z]:((\\){1,2}[^\\]+)+)(\\)?$/i;

    // Add autoprefix.
    if (
      editor.opts.linkAutoPrefix !== '' &&
      !new RegExp('^(' + FE.LinkProtocols.join('|') + '):.', 'i').test(href) &&
      !/^data:image.*/i.test(href) &&
      !/^(https?:|ftps?:|file:|)\/\//i.test(href) &&
      !local_path.test(href)
    ) {
      // Do prefix only if starting character is not absolute.
      if (['/', '{', '[', '#', '(', '.'].indexOf((href || '')[0]) < 0) {
        href = editor.opts.linkAutoPrefix + href;
      }
    }
    if (width === '') width = '100%';
    if (height === '') height = '500px';
    let frameURL = new URL(href);
    const isGoogleDoc = frameURL.href.startsWith('https://docs.google.com/document');
    if (isGoogleDoc) {
      if (frameURL.search !== '?embedded=true') {
        frameURL += '?embedded=true';
      }
    }

    // Some file extensions need special treatment to be embedded
    if (/(.mov|.mp4|.mkv|.ogg|.webm)$/.test(frameURL.pathname)) {
      const hash = frameURL.hash.length > 0 ? '' : '#t=0.01';
      editor.html.insert(
        `<span class="fr-video fr-fvc fr-dvi fr-draggable" contenteditable="false"><video controls="controls" name="Video file"><source src="${frameURL}${hash}" ></video></span>`,
      );
    } else if (/(.mp3|.wav)$/.test(frameURL.pathname)) {
      editor.html.insert(`<audio controls="controls" name="Audio file"><source src="${frameURL}" ></audio>`);
    } else if (/(.doc|.docx|.pptx|.odt)$/.test(frameURL.pathname)) {
      editor.html.insert(
        `<embed src="https://view.officeapps.live.com/op/embed.aspx?src=${frameURL}" width="${width}" height="${height}"></embed>`,
      );
    } else if (/.rtf$/.test(frameURL.pathname)) {
      editor.html.insert(
        `<embed src="https://docs.google.com/gview?url=${frameURL}&embedded=true" width="${width}" height="${height}"></embed>`,
      );
    } else if (/(.xls|.xlsx|.pdf)$/.test(frameURL.pathname)) {
      editor.html.insert(`<embed src="${frameURL}" width="${width}" height="${height}"></embed>`);
    } else {
      editor.html.insert(`<iframe src="${frameURL}" width="${width}" height="${height}"></iframe>`);
    }

    hidePopup();
    text_inputs.filter('[name="href"]').val('');
    text_inputs.filter('[name="width"]').val('');
    text_inputs.filter('[name="height"]').val('');
  }
  // Methods visible outside the plugin.
  return {
    showPopup,
    hidePopup,
    insertLink,
  };
};

// Define an icon and command for the button that opens the custom popup.
FE.DefineIconTemplate(
  'iframIcon',
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M128 96h384v256h64V80C576 53.63 554.4 32 528 32h-416C85.63 32 64 53.63 64 80V352h64V96zM624 384h-608C7.25 384 0 391.3 0 400V416c0 35.25 28.75 64 64 64h512c35.25 0 64-28.75 64-64v-16C640 391.3 632.8 384 624 384zM365.9 286.2C369.8 290.1 374.9 292 380 292s10.23-1.938 14.14-5.844l48-48c7.812-7.813 7.812-20.5 0-28.31l-48-48c-7.812-7.813-20.47-7.813-28.28 0c-7.812 7.813-7.812 20.5 0 28.31l33.86 33.84l-33.86 33.84C358 265.7 358 278.4 365.9 286.2zM274.1 161.9c-7.812-7.813-20.47-7.813-28.28 0l-48 48c-7.812 7.813-7.812 20.5 0 28.31l48 48C249.8 290.1 254.9 292 260 292s10.23-1.938 14.14-5.844c7.812-7.813 7.812-20.5 0-28.31L240.3 224l33.86-33.84C281.1 182.4 281.1 169.7 274.1 161.9z"/></svg>',
);
FE.DefineIcon('iframeIcon', { NAME: 'checklist', template: 'iframIcon' });
FE.RegisterCommand('iframeinsert', {
  title: localizedStrings.global.addurl,
  icon: 'iframeIcon',
  undo: false,
  focus: false,
  plugin: 'iframePlugin',
  callback: function () {
    this.iframePlugin.showPopup();
  },
});

FE.RegisterCommand('iframeLinkInsert', {
  focus: false,
  refreshAfterCallback: false,
  callback: function () {
    this.iframePlugin.insertLink();
  },
});
