export default [
  { to: 'akut', title: 'Akut' },
  { to: 'behandlingsanvisningar', title: 'Behandlings anvisningar' },
  { to: 'lakemedel', title: 'Läkemedel' },
  { to: 'pm', title: 'PM' },
  { to: 'information', title: 'Info' },
  { to: 'allmant', title: 'Allmänt' },
  { to: 'ekg', title: 'EKG' },
  { to: 'arkiv', title: 'Arkiv' },
];
