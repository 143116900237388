import { ReactElement } from 'react';
import { useIsDraft } from '../ContextProviders/AppContext';
import { ArticleBodyEdit } from './ArticleBodyEdit';
import { ArticleBodyPublish } from './ArticleBodyPublish';
import './ArticleBody.scss';
import { FabButtonArticle } from '../FabButton/FabButton';

interface ArticleBodyProps {
  id: string;
}
export const ArticleBody = ({ id }: ArticleBodyProps): ReactElement => {
  const [isDraft] = useIsDraft();

  return (
    <div style={{ display: 'flex' }}>
      <FabButtonArticle id={id} />
      {isDraft ? <ArticleBodyEdit id={id} /> : <ArticleBodyPublish id={id} key={id} />}
    </div>
  );
};
