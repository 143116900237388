import { createContext, ReactElement, ReactNode, useContext, useEffect, useState } from 'react';
import {
  getAuth,
  User,
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  signInWithCustomToken,
} from 'firebase/auth';
import ReactDOM from 'react-dom';
import { useConcreteProject } from './ProjectContext';
import { toasts } from '../../shared';
import { localizedStrings } from '../../localizedStrings';
import { useFirebase, useMonofunction } from './Firebase';
import { useIsDraft } from './AppContext';
import { useDocument } from '../../Hooks';
import { CustomClaims } from '../../Types';
import { UserRole } from '@eir/core';

export interface AppUser {
  user: User | null;
  isAdmin: boolean;
  isStaff: boolean;
  signIn: (email: string, password: string) => void;
  signInStaff: (password: string) => void;
  signOut: () => void;
  status: Status;
  message: string;
  resetStatus: () => void;
  categoryPermissions: string[];
}

export enum Status {
  IDLE,
  IN_FLIGHT,
  ERROR,
}

export const useAuth = (): AppUser => useContext(AuthContext);
function useProvideAuth(): AppUser {
  const firebase = useFirebase();
  const functions = useMonofunction();
  const [user, setUser] = useState<User | null>(() => {
    return getAuth(firebase).currentUser;
  });
  const [, setDraft] = useIsDraft();
  const [userType, setUserType] = useState<UserRole | string>(UserRole.NONE);
  const [status, setStatus] = useState(Status.IDLE);
  const [message, setMessage] = useState('');
  const [categoryPermissions, setCategoryPermissions] = useState<string[]>([]);
  const project = useConcreteProject();
  const claimsNotifier = useDocument<CustomClaims>(user?.uid ? `/tokenMetaData/${user?.uid}` : undefined, {});

  const resetStatus = () => {
    ReactDOM.unstable_batchedUpdates(() => {
      setStatus(Status.IDLE);
      setMessage('');
    });
  };
  const invalidLoginStatus = () => {
    ReactDOM.unstable_batchedUpdates(() => {
      setStatus(Status.ERROR);
      setMessage('Login failed: Please try again.');
    });
  };

  const signIn = (email: string, password: string) => {
    setStatus(Status.IN_FLIGHT);
    signInWithEmailAndPassword(getAuth(firebase), email, password)
      .then(() => {
        toasts.success(`${localizedStrings.auth.authenticated} ${localizedStrings.auth.adminAccount.toLowerCase()}`);
      })
      .catch(({ reason }) => {
        console.log(reason);
        toasts.error(localizedStrings.auth.error.invalidCredentials);
        invalidLoginStatus();
      });
  };
  const signOut = () => {
    setDraft(false);
    void firebaseSignOut(getAuth(firebase)).then(() => toasts.success(localizedStrings.auth.loggedout));
  };
  const signInStaff = (password: string) => {
    setStatus(Status.IN_FLIGHT);
    void functions
      .PasswordOnlyLogin({ password })
      .then((token: string) => {
        signInWithCustomToken(getAuth(firebase), token)
          .then(() => {
            toasts.success(
              `${localizedStrings.auth.authenticated} ${localizedStrings.auth.staffAccount.toLowerCase()}`,
            );
          })
          .catch(({ reason }) => {
            console.log(reason);
            toasts.error(localizedStrings.auth.error.invalidStaffPassword);
            invalidLoginStatus();
          });
      })
      .catch((reason) => {
        console.log(reason);
        toasts.error(localizedStrings.auth.error.invalidStaffPassword);
        invalidLoginStatus();
      });
  };
  const handleClaims = async (user: User | null) => {
    if (user === null) {
      ReactDOM.unstable_batchedUpdates(() => {
        setUser(null);
        setUserType(UserRole.NONE);
        setStatus(Status.IDLE);
        setCategoryPermissions([]);
      });
      return;
    }

    const { role, categoryPermissions: cp } = (await user.getIdTokenResult(true)).claims;

    if (role === UserRole.SUPER_ADMIN || role === UserRole.ADMIN || role === UserRole.STAFF) {
      ReactDOM.unstable_batchedUpdates(() => {
        setUser(user);
        setStatus(Status.IDLE);
        setUserType(role);
        setCategoryPermissions(cp ? (cp as string[]) : []);
      });
    } else {
      toasts.error('Invalid user type');
      console.log(`Invalid user type: ${role}`);
      ReactDOM.unstable_batchedUpdates(() => {
        setUser(null);
        setStatus(Status.IN_FLIGHT);
        setUserType(UserRole.NONE);
        setCategoryPermissions([]);
      });
      signOut();
      return;
    }
  };

  useEffect(() => {
    const unsubscribe = getAuth(firebase).onAuthStateChanged((user) => {
      void handleClaims(user);
    });
    return unsubscribe;
    // eslint-disable-next-line
  }, [project, firebase]);

  useEffect(() => {
    if (claimsNotifier.doc.claimsLastUpdated && user) {
      void handleClaims(user);
    }
    // eslint-disable-next-line
  }, [claimsNotifier.doc]);

  return {
    user,
    status,
    message,
    signIn,
    signOut,
    resetStatus,
    signInStaff,
    get isAdmin() {
      return userType === UserRole.SUPER_ADMIN || userType === UserRole.ADMIN;
    },
    get isStaff() {
      return userType === UserRole.STAFF;
    },
    categoryPermissions,
  };
}

const AuthContext = createContext<AppUser>({} as AppUser);
export function AuthProvider({ children }: { children?: ReactNode }): ReactElement {
  return <AuthContext.Provider value={useProvideAuth()}>{children}</AuthContext.Provider>;
}
