export default [
  { to: 'oppenvard', title: 'Öppenvård' },
  { to: 'slutenvard', title: 'Sjukhusvård' },
  { to: 'profylax', title: 'Profylax' },
  { to: 'tandvard', title: 'Tandvård' },
  { to: 'veterinar_allmant', title: 'Nöt' },
  { to: 'smadjur', title: 'Smådjur' },
  { to: 'hast', title: 'Häst' },
  { to: 'gris', title: 'Gris' },
  { to: 'sma-idisslare', title: 'Små idisslare' },
];
