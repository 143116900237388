export default [
  { to: 'allmant', title: 'Allmänt' },
  { to: 'behandlingsriktlinjer', title: 'Behandlingsriktlinjer' },
  { to: 'calculator', title: 'Dosberäkning', lockExternal: true },
  { to: 'ekg', title: 'EKG-handbok' },
  { to: 'ambulans_intern', title: 'Ambulans intern' },
  { to: 'changelog', title: 'Ändringslogg' },
  { to: 'akm_uso', title: 'Introduktion' },
  { to: 'arkiv', title: 'Arkiv' },
];
