import { faEdit, faEye, faSave, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { articleActions, changelogArticleActions, chiefArticleActions } from '../../Hooks/DatabaseActions';
import { localizedStrings } from '../../localizedStrings';
import { toasts } from '../../shared';
import { IconButton } from '../Buttons/Buttons';
import { useArticle, useCategory } from '../ContextProviders/AppContext';
import { useFirestore } from '../ContextProviders/Firebase';
import { useModalSpawn } from '../ContextProviders/ModalContext';
import { useConcreteProject } from '../ContextProviders/ProjectContext';
import { ArticleEditor } from '../Editor/ArticleEditor';
import { PlatformContentFrame } from '../PlatformContentFrame/PlatformContentFrame';
import './ArticleBodyEdit.scss';
import { ArticleLocation } from './ArticleLocation';
import { NotSavedArticleModal } from './NotSavedModal';

interface ArticleBodyProps {
  id: string;
}
export const ArticleBodyEdit = ({ id }: ArticleBodyProps): ReactElement => {
  const article = useArticle(id);
  const category = useCategory(article?.category);
  const firestore = useFirestore();
  const project = useConcreteProject();

  if (article === undefined || category === undefined) return <></>;

  const save = (content: string, articleId: string) => {
    if (article.isChief) {
      return chiefArticleActions(firestore, project.id).update(articleId, {
        content: content,
        name: article.name,
        orderIndex: article.orderIndex,
        section: article.section,
        category: article.category,
      });
    } else if (article.isChangelog) {
      return changelogArticleActions(firestore, project.id).update(articleId, {
        content: content,
        name: article.name,
        orderIndex: article.orderIndex,
        section: article.section,
        category: article.category,
      });
    }
    return articleActions(firestore, project.id).update(articleId, { content: content });
  };

  return (
    <article>
      <div className="article-body editable-content">
        <EditableContent
          content={article.content}
          save={(content) => save(content, id)}
          key={id}
          headerInject={<ArticleLocation id={id} />}
        />
      </div>
    </article>
  );
};

interface EditableContetProps {
  content: string;
  save: (content: string) => Promise<unknown>;
  headerInject?: ReactElement;
}
export const EditableContent = ({ content: _content, save, headerInject }: EditableContetProps): ReactElement => {
  const history = useHistory();
  const spawn = useModalSpawn();

  const [content, setContent] = useState(history.location.state?.editableContent?.content || _content);
  const [editing, setEditing] = useState(history.location.state?.editableContent?.editing || false);
  const [isDirty, setDirty] = useState<boolean>(history.location.state?.editableContent?.isDirty || false);
  const isDirtyNow = useRef(isDirty);
  isDirtyNow.current = isDirty;
  const contentNow = useRef(content);
  contentNow.current = content;

  const editorContainerRef = useRef<HTMLDivElement>(null);
  const [editorHeight, setEditorHeight] = useState<number>(0);

  useEffect(() => {
    //Setting this to prevent overflow from the editor
    if (editorContainerRef.current) setEditorHeight(editorContainerRef.current.clientHeight);
  }, []);

  useEffect(() => {
    history.replace(`${history.location.pathname}${history.location.hash}`, {
      ...history.location.state,
      editableContent: JSON.parse(JSON.stringify({ editing, isDirty, content })),
    });
    // eslint-disable-next-line
  }, [editing, isDirty, content]);

  const saveRef = useRef(() => save(contentNow.current));

  useEffect(() => {
    return () => {
      if (isDirtyNow.current && contentNow.current) {
        spawn(
          'articleUnsavedModal',
          <NotSavedArticleModal
            onDiscardChanges={() => {
              spawn('articleUnsavedModal', <></>);
            }}
            onSave={() => {
              saveRef
                // eslint-disable-next-line
                .current()
                .then(() => {
                  toasts.success(localizedStrings.article.updateOk);
                })
                .catch(() => {
                  toasts.error(localizedStrings.article.updateFailed);
                });
              spawn('articleUnsavedModal', <></>);
            }}
            onCancel={() => {
              history.goBack();
              spawn('articleUnsavedModal', <></>);
            }}
            isOpen={true}
          />,
        );
      }
    };
    // eslint-disable-next-line
  }, []);

  const reset = (rcontent: string) => {
    setContent(rcontent);
    setEditing(false);
    setDirty(false);
  };

  const saveContent = (content: string) => {
    save(content)
      .then(() => {
        toasts.success(localizedStrings.article.updateOk);
        setEditing(false);
        setDirty(false);
      })
      .catch((e) => {
        toasts.error(localizedStrings.article.updateFailed);
      });
  };

  return (
    <>
      <div className="editable-content-head">
        {headerInject && headerInject}
        <div className="edit-view-cancel-buttons">
          <EditViewCancel
            isDirty={isDirty}
            editing={editing}
            onEditToggle={() => setEditing((ed) => !ed)}
            onSave={() => saveContent(content)}
            onCancel={() => reset(_content)}
          />
        </div>
      </div>
      <div className="editable-content-body" ref={editorContainerRef}>
        {!editing ? (
          <PlatformContentFrame body={content} bodyClass="edit-mode" />
        ) : (
          <ArticleEditor
            editStatus={true}
            onChange={(c) => {
              setContent(c);
              setDirty(true);
            }}
            content={content}
            maxHeight={editorHeight}
          />
        )}
      </div>
    </>
  );
};

interface EditViewCancelProps {
  isDirty: boolean;
  editing: boolean;
  onEditToggle: () => void;
  onSave: () => void;
  onCancel: () => void;
}
const EditViewCancel = ({ isDirty, editing, onEditToggle, onSave, onCancel }: EditViewCancelProps) => {
  return (
    <>
      <IconButton
        title="Redigera/Visa"
        onClick={() => {
          onEditToggle();
        }}
        color="primary"
        icon={editing ? faEye : faEdit}
        text={editing ? localizedStrings.global.preview : localizedStrings.global.edit}
      />

      {isDirty && (
        <>
          <IconButton
            title="Spara"
            onClick={() => {
              onSave();
            }}
            color="primary"
            icon={faSave}
            text={localizedStrings.global.save}
          />
          <IconButton
            title="Avbryt"
            onClick={() => {
              onCancel();
            }}
            color="primary"
            icon={faTimes}
            text={localizedStrings.global.cancel}
          />
        </>
      )}
    </>
  );
};
