import { ReactElement } from 'react';
import { localizedStrings } from '../../localizedStrings';
import { useArticle, useCategoryTree, useFullscreen, useProjectConfig } from '../ContextProviders/AppContext';
import { useStyle } from '../ContextProviders/Style';
import { Action, Fab } from './Fab';
import { AppIcon } from './Icon';

export const FabButtonCategory = ({ id }: { id: string }): ReactElement => {
  const tree = useCategoryTree(id);

  const printAllArticles = () => {
    let allContent = '<style> * { color: black; } </style>';

    if (!tree) return;

    const allArticlesOrdered = [
      ...tree.sections.reduce((acc, section) => {
        return [...acc, ...section.articles];
      }, []),
      ...tree.defaultSection,
    ];

    allArticlesOrdered.forEach((b) => {
      allContent += ` <hr><center> ${localizedStrings.article.article}: ${b.name}</center><hr>`;
      allContent += b.content;
      allContent += '<br><br><br>';
    });
    const theTitle = tree.name;
    const win = window.open();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    win!.document.title = theTitle || '';
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    win!.document.body.innerHTML = allContent;
    // eslint-disable-next-line
    Promise.all(
      // eslint-disable-next-line
      Array.from(win!.document.images).map((img) => {
        if (img.complete) return Promise.resolve(img.naturalHeight !== 0);
        return new Promise((resolve) => {
          img.addEventListener('load', () => resolve(true));
          img.addEventListener('error', () => resolve(false));
        });
      }),
    ).then((results) => {
      // eslint-disable-next-line
      win!.requestAnimationFrame(() => {
        // eslint-disable-next-line
        win!.requestAnimationFrame(() => {
          // eslint-disable-next-line
          win!.print();
        });
      });
    });
  };

  return <FabButton onPrint={printAllArticles} />;
};

export const FabButtonArticle = ({ id }: { id: string }): ReactElement => {
  const article = useArticle(id);

  const onPrint = (): void => {
    if (!article) return;

    const theTitle = `${document.title} (${window.location.host})`;
    const win = window.open();
    // eslint-disable-next-line
    win!.document.title = theTitle;
    // eslint-disable-next-line
    win!.document.body.innerHTML = `<style> * { color: black; } </style>${article.content}`;

    // eslint-disable-next-line
    Promise.all(
      // eslint-disable-next-line
      Array.from(win!.document.images).map((img) => {
        if (img.complete) return Promise.resolve(img.naturalHeight !== 0);
        return new Promise((resolve) => {
          img.addEventListener('load', () => resolve(true));
          img.addEventListener('error', () => resolve(false));
        });
      }),
    ).then((results) => {
      // eslint-disable-next-line
      win!.requestAnimationFrame(() => {
        // eslint-disable-next-line
        win!.requestAnimationFrame(() => {
          // eslint-disable-next-line
          win!.print();
        });
      });
    });
  };

  return <FabButton onPrint={onPrint} />;
};

export const FabButton = ({ onPrint }: { onPrint?: () => void }): ReactElement => {
  const config = useProjectConfig();
  const style = useStyle();
  const [fs, setFs] = useFullscreen();
  if (config.loading || config.error) {
    return <></>;
  }

  const generateMailTo = (): void => {
    const temp = config.doc.feedbackEmails.join(',');
    window.open(
      `mailto:${temp}?subject=${localizedStrings.global.feedbackSubject}&body= Link: ${window.location.href}`,
    );
  };

  const toggleFullscreen = (): void => {
    setFs(!fs);
  };

  return (
    <div data-toggle="tooltip" data-placement="left" title={localizedStrings.global.feedback.feedbackAndPrint}>
      <Fab
        mainButtonStyles={{
          backgroundColor: `${style.primaryLight}`,
          color: style.primaryDark,
        }}
        position={{ bottom: '25px', right: '10px' }}
        icon={<AppIcon icon="bars" />}
        event="hover"
      >
        {config.doc.feedbackEmails ? (
          <Action
            style={{
              backgroundColor: `${style.primaryLight}`,
              color: style.primaryDark,
            }}
            data-toggle="tooltip"
            data-placement="left"
            title={localizedStrings.global.feedback.send}
            onClick={generateMailTo}
          >
            <AppIcon icon="envelope" />
          </Action>
        ) : (
          <Action
            style={{
              backgroundColor: `${style.primaryLight}`,
              color: style.primaryDark,
            }}
            text={localizedStrings.global.feedback.noFeedbackEmailExist}
          >
            <AppIcon icon="commentSlash" />
          </Action>
        )}

        {onPrint && (
          <Action
            style={{ backgroundColor: `${style.primaryLight}`, color: style.primaryDark }}
            data-toggle="tooltip"
            data-placement="left"
            title={localizedStrings.global.print}
            onClick={onPrint}
          >
            <AppIcon icon="print" />
          </Action>
        )}

        <Action
          style={{ backgroundColor: `${style.primaryLight}`, color: style.primaryDark }}
          data-toggle="tooltip"
          data-placement="left"
          title={localizedStrings.global.fullscreen}
          onClick={toggleFullscreen}
        >
          <AppIcon icon="expand" />
        </Action>
      </Fab>
    </div>
  );
};
