import { Link } from 'react-router-dom';
import { useArticle, useCategory, useSection } from '../ContextProviders/AppContext';

export const ArticleLocation = ({ id }: { id: string }) => {
  const article = useArticle(id);
  const section = useSection(article?.section);
  const category = useCategory(article?.category);
  if (!article || !category) return <></>;
  return (
    <div className="article-location">
      <span>
        <Link to={{ pathname: `/category/${category.fId}`, state: { sectionId: section?.fId } }}>
          {category.name}
          {section && ` > ${section.name}`}
        </Link>
      </span>
    </div>
  );
};
