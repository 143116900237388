import { useEffect } from 'react';
import { useMonofunction } from '../ContextProviders/Firebase';

/**
 * To avoid cold starts:
 * Ping monofunction on first load - this warms up function if cold
 * Then, ping more often than the timeout is for that function. Only keep function warm when window is in focus.
 */
export const MonofunctionWarmer = () => {
  const monofunction = useMonofunction();

  useEffect(() => {
    let lastPing = 0;
    let pingInterval;
    const onWindowFocus = () => {
      if (lastPing < Date.now() - 8 * 60 * 1000) {
        //8min, timeout is 9min
        lastPing = Date.now();
        void monofunction.Ping().catch(() => {
          console.log('Failed to ping firebase');
        });
      }
      clearInterval(pingInterval);
      pingInterval = setInterval(() => {
        void monofunction.Ping().catch(() => {
          console.log('Failed to ping firebase');
        });
      }, 8 * 60 * 1000);
    };
    const onWindowBlur = () => {
      clearInterval(pingInterval);
    };
    window.addEventListener('focus', onWindowFocus);
    window.addEventListener('blur', onWindowBlur);
    onWindowFocus();

    return () => {
      clearInterval(pingInterval);
      window.removeEventListener('focus', onWindowFocus);
      window.removeEventListener('blur', onWindowBlur);
    };
    //eslint-disable-next-line
  }, []);

  return <></>;
};
