import { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core';
import {
  faFolderPlus,
  faSpinner,
  faEyeSlash,
  faInfoCircle,
  faMailBulk,
  faCommentAltSlash,
  faSort,
  faSortAlphaDown,
  faSortAlphaUp,
} from '@fortawesome/pro-solid-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faCog } from '@fortawesome/pro-solid-svg-icons/faCog';
import { faEnvelopeSquare } from '@fortawesome/pro-solid-svg-icons/faEnvelopeSquare';
import { faEye } from '@fortawesome/pro-solid-svg-icons/faEye';
import { faFileEdit } from '@fortawesome/pro-solid-svg-icons/faFileEdit';
import { faFilePlus } from '@fortawesome/pro-solid-svg-icons/faFilePlus';
import { faLockAlt } from '@fortawesome/pro-solid-svg-icons/faLockAlt';
import { faPrint } from '@fortawesome/pro-solid-svg-icons/faPrint';
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { faSignOutAlt } from '@fortawesome/pro-solid-svg-icons/faSignOutAlt';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import { faLockOpenAlt } from '@fortawesome/pro-solid-svg-icons/faLockOpenAlt';
import { faUpload } from '@fortawesome/pro-solid-svg-icons/faUpload';
import { faUserAlt } from '@fortawesome/pro-solid-svg-icons/faUserAlt';
import { faBars } from '@fortawesome/pro-solid-svg-icons/faBars';
import { faExpand } from '@fortawesome/pro-solid-svg-icons/faExpand';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useStyle } from '../ContextProviders/Style';

interface IconSpecs {
  color?: string;
  size?: SizeProp;
}

export type IconType =
  | 'create'
  | 'eye'
  | 'eyeSlash'
  | 'publish'
  | 'trash'
  | 'fileEdit'
  | 'user'
  | 'lock'
  | 'unlocked'
  | 'check'
  | 'cancel'
  | 'settings'
  | 'envelope'
  | 'lens'
  | 'out'
  | 'folderPlus'
  | 'print'
  | 'spinner'
  | 'info'
  | 'mailBulk'
  | 'commentSlash'
  | 'bars'
  | 'expand'
  | 'sort'
  | 'sortAlphaUp'
  | 'sortAlphaDown';

interface Props {
  onClick?: () => void;
  title?: string;
  content?: string;
  color?: string;
  size?: SizeProp;
  icon: IconType;
  className?: string;
  style?: React.CSSProperties;
}

const iconContainerStyles = 'd-flex';
const iconStyles = 'pr-1 pl-1';

const getIcon = (icon: IconDefinition, iconSpec?: IconSpecs) => (
  // eslint-disable-next-line
  <FontAwesomeIcon color={iconSpec!.color} size={iconSpec!.size} icon={icon} />
);

export const AppIcon: React.FunctionComponent<Props> = (props: Props) => {
  const style = useStyle();
  const iconSpecs = {
    color: props.color ? props.color : style.primaryDark,
    size: props.size ? props.size : '1x',
  };

  const icons = {
    create: getIcon(faFilePlus, iconSpecs),
    eye: getIcon(faEye, iconSpecs),
    publish: getIcon(faUpload, iconSpecs),
    trash: getIcon(faTrash, iconSpecs),
    fileEdit: getIcon(faFileEdit, iconSpecs),
    user: getIcon(faUserAlt, iconSpecs),
    lock: getIcon(faLockAlt, iconSpecs),
    unlocked: getIcon(faLockOpenAlt, iconSpecs),
    check: getIcon(faCheck, iconSpecs),
    cancel: getIcon(faTimes, iconSpecs),
    settings: getIcon(faCog, iconSpecs),
    envelope: getIcon(faEnvelopeSquare, iconSpecs),
    lens: getIcon(faSearch, iconSpecs),
    out: getIcon(faSignOutAlt, iconSpecs),
    folderPlus: getIcon(faFolderPlus, iconSpecs),
    print: getIcon(faPrint, iconSpecs),
    spinner: getIcon(faSpinner, iconSpecs),
    eyeSlash: getIcon(faEyeSlash, iconSpecs),
    info: getIcon(faInfoCircle, iconSpecs),
    mailBulk: getIcon(faMailBulk, iconSpecs),
    commentSlash: getIcon(faCommentAltSlash, iconSpecs),
    bars: getIcon(faBars, iconSpecs),
    expand: getIcon(faExpand, iconSpecs),
    sort: getIcon(faSort, iconSpecs),
    sortAlphaUp: getIcon(faSortAlphaUp, iconSpecs),
    sortAlphaDown: getIcon(faSortAlphaDown, iconSpecs),
  };

  const icon = icons[props.icon];

  return (
    <div title={props.title} onClick={props.onClick} className={iconContainerStyles}>
      <span className={iconStyles} style={props.style}>
        {icon ? icon : null} {props.content ? props.content : null}
      </span>
    </div>
  );
};

AppIcon.defaultProps = {
  onClick: () => null,
};
