import { useState, useEffect, ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/pro-solid-svg-icons';
import CategoryNavigation from './CategoryNavigation';
import './HamburgerMenu.scss';
import { DraftModeSwitcher, PublishButton, SearchInput, SettingsButton, UserNav } from './Header';

export const HamburgerMenu = (): ReactElement => {
  const [isToggled, setIsToggled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsToggled(false);
  }, [location]);

  return (
    <>
      <div className="icon-hamburger-menu">
        <button className="toggle-hamburger-icon" onClick={() => setIsToggled(true)}>
          <FontAwesomeIcon icon={faBars} />
        </button>
      </div>

      <div className={'hamburger-menu-box ' + (isToggled ? 'visible' : 'hidden')}>
        <div className="d-flex justify-content-between align-items-center hamburger-menu-top">
          <div className="header-top-row-right">
            <SearchInput />
          </div>
          <div className="hamburger-menu-top-icon">
            <button className="toggle-hamburger-icon" onClick={() => setIsToggled(false)}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>
        <div className="hamburger-menu-body">
          <CategoryNavigation />
        </div>
        <div className="hamburger-menu-bottom mt-auto">
          <SettingsButton />
          <PublishButton />
          <DraftModeSwitcher />
          <UserNav />
        </div>
      </div>
    </>
  );
};
