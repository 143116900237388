import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as ReactDOM from 'react-dom';
import './bootstrap.scss';
import { App } from './components/App/App';
import * as serviceWorker from './serviceWorkerRegistration';
import { ErrorBoundary } from './components/Error/BugsnagErrorBoundary';
import { ErrorView } from './components/Error/DefaultErrorView';
import { reportError } from './components/Error/Bugsnag';

ReactDOM.render(
  <ErrorBoundary FallbackComponent={ErrorView} onError={reportError}>
    <App />
  </ErrorBoundary>,
  document.getElementById('root') as HTMLElement,
);
serviceWorker.register();
