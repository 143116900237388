import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

//  Top-level error reporting catch. Can be customized wherever BugsnagErrorBoundary is used by supplying an onError func.
export const reportError = (event) => {
  Bugsnag.notify(event);
};

// These are added in .gitlab-ci.yaml when building
Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API || '-',
  plugins: [new BugsnagPluginReact()],
  appVersion: process.env.REACT_APP_PROJECT || '-',
});

export default Bugsnag;
