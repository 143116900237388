import './Buttons.scss';
import { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { IconDefinition, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { Button as ReactButton } from 'reactstrap';
import { LoadingSVG } from '../Pages/Loading/Loading';

// eslint-disable-next-line
export const DarkButton = (props: any): ReactElement => (
  <button {...props} className={`${props.className || ''} dark-button simple-button`} />
);
// eslint-disable-next-line
export const LightButton = (props: any): ReactElement => (
  <button {...props} className={`${props.className || ''} light-button simple-button`} />
);
// eslint-disable-next-line
export const Button = (props: any): ReactElement => (
  <button {...props} className={`${props.className || ''} simple-button`} />
);
// eslint-disable-next-line
export const HoverButton = (props: any): ReactElement => (
  <button {...props} className={`${props.className || ''} simple-button hover-half-active`} />
);

interface IconButtonProps {
  icon: IconDefinition;
  text?: string;
  useCustomSpinner?: boolean;
  isLoading?: boolean;
  style?: React.CSSProperties;
  theme?: 'light' | 'dark';
  tooltip?: string;
  tooltipPosition?: TooltipPosition;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}
export const IconButton = ({
  icon,
  text,
  useCustomSpinner,
  isLoading,
  style,
  theme,
  tooltip,
  tooltipPosition,
  ...props
}: IconButtonProps): ReactElement => {
  return (
    <ReactButton
      style={{ border: 'none', ...style }}
      className={`div-hover ${theme === 'dark' ? 'dark-button' : theme === 'light' ? 'light-button' : ''}`}
      disabled={isLoading}
      {...props}
    >
      <div>
        {useCustomSpinner ? (
          isLoading ? (
            <LoadingSVG size={18} />
          ) : (
            <FontAwesomeIcon icon={icon} style={{ transform: 'translateY(1px)' }} />
          )
        ) : (
          <FontAwesomeIcon icon={isLoading ? faSpinner : icon} style={{ transform: 'translateY(1px)' }} />
        )}
        {text && <span className="ms-1">{text}</span>}
      </div>
      {tooltip && <Tooltip text={tooltip} position={tooltipPosition} />}
    </ReactButton>
  );
};

type TooltipPosition = 'bottom' | 'top' | 'left' | 'right';
const Tooltip = ({ text, position }: { text: string; position: TooltipPosition | undefined }): ReactElement => {
  // Tooltips are allowed to overflow the screen edges. Perhaps a TODO fix for the future?
  return <span className={`tooltip-content ${position || 'bottom'}`}>{text}</span>;
};

interface MiniClickableProps {
  children?: ReactElement | string;
  size?: SizeProp;
  icon: IconProp;
}
export const MiniClickableIcon = ({
  icon,
  children,
  size = 'sm',
  ...props
}: MiniClickableProps & Record<string, unknown>): ReactElement => (
  <span className="mini-clickable-button" style={{ cursor: 'pointer' }} {...props}>
    <FontAwesomeIcon icon={icon} size={size} />
    {children}
  </span>
);
