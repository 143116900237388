export default [
  { to: 'akut', title: 'Akut' },
  { to: 'behandlingsriktlinjer', title: 'Behandlingsriktlinjer' },
  /*   { to: 'lakemedel', title: 'Läkemedel' }, */
  { to: 'rutiner', title: 'Rutiner' },
  { to: 'information', title: 'Information' },
  { to: 'kontaktvagar', title: 'Kontaktvägar' },
  { to: 'calculator', title: 'Doskalkylator' },
  { to: 'changelog', title: 'Ändringslogg' },
];
