export default [
  { to: 'about', title: 'Om Smart Yta' },
  { to: 'allmant', title: 'Allmänt' },
  { to: 'motala2', title: 'Motala etapp 2' },
  { to: 'motala3', title: 'Motala etapp 3' },
  { to: 'linkoping1', title: 'Linköping etapp 1' },
  { to: 'oskarshamn1', title: 'Oskarshamn etapp 1' },
  { to: 'kalmar1', title: 'Kalmar etapp 1' },
  { to: 'vaxjo1', title: 'Växjö etapp 1' },
  { to: 'tranas1', title: 'Tranås etapp 1' },
  { to: 'changelog', title: 'Ändringslogg' },
  { to: 'admin', title: 'Admin' },
];
