import { ReactElement } from 'react';
import { useArticle, useCategory } from '../ContextProviders/AppContext';
import { PlatformContentFrame } from '../PlatformContentFrame/PlatformContentFrame';

import './ArticleBodyPublish.scss';
import { ArticleLocation } from './ArticleLocation';

interface ArticleBodyProps {
  id: string;
}
export const ArticleBodyPublish = ({ id }: ArticleBodyProps): ReactElement => {
  const article = useArticle(id);
  const category = useCategory(article?.category);

  if (!article || !category) return <></>;

  return (
    <article>
      <ArticleLocation id={id} />
      <div className="article-body">
        <PlatformContentFrame body={article.content} key={id} />
      </div>
    </article>
  );
};
