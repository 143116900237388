import { Button } from '../Buttons/Buttons';
import { localizedStrings } from '../../localizedStrings';

export const ErrorView = ({ clearError }) => (
  <div style={{ padding: '1em', textAlign: 'center', width: 'fit-content' }}>
    <p>{localizedStrings.error.unknown}</p>
    <Button className={'btn btn-primary'} onClick={() => window.location.reload()}>
      {localizedStrings.global.clickToReloadFull}
    </Button>
  </div>
);
