import { ReactElement, useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';

import './Header.scss';
import {
  useArticle,
  useArticles,
  useArticlesChangelog,
  useArticlesDraft,
  useCategories,
  useChiefArticles,
  useFullscreen,
  useIsDraft,
  useProjectConfig,
} from '../ContextProviders/AppContext';

import { useAuth } from '../ContextProviders/Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/pro-solid-svg-icons/faSignOutAlt';
import { faSignIn, faEdit, faEye, faUpload, faCog, faSearch } from '@fortawesome/pro-solid-svg-icons';
import { useConcreteProject } from '../ContextProviders/ProjectContext';
import { PublishResult, Article } from '../../Types';
import { Button, DropdownItem, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useFirestore } from '../ContextProviders/Firebase';
import { appActions } from '../../Hooks/DatabaseActions';
import { Modal, Input, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { toasts } from '../../shared';
import { ConsistencyReportData } from '@eir/core';
import CategoryNavigation from './CategoryNavigation';
import { localizedStrings } from '../../localizedStrings';
import { useSearch } from '../ContextProviders/SearchContext';
import { getByFId } from '../../util/DataHelpers';
import { PublishMessageModal } from './PublishModal';
import { useOnline } from '../../Hooks/useOnline';
import { HamburgerMenu } from './HamburgerMenu';
import { IconButton } from '../Buttons/Buttons';

const AppLogo = (): ReactElement => {
  const project = useConcreteProject();
  const projectConfig = useProjectConfig();

  return (
    <div className="header-top-row-left">
      <Link to="/">
        <img src={`/assets/images/${project.id}/logo.png`} alt={`${project.id} logo`} height={35} />
        <h1>{projectConfig.doc.name}</h1>
      </Link>
    </div>
  );
};

const SEARCH_RESULTS_MAX = 5;
export const SearchInput = () => {
  const [searchText, setSearchText] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [openDelayed, setOpenDelayed] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1); //-1 = none active
  const searchResults = useSearch(searchText).slice(0, SEARCH_RESULTS_MAX);

  const articles = useArticles();
  const chiefArticles = useChiefArticles();
  const changelogArticles = useArticlesChangelog();

  const timeout = useRef(0);
  const history = useHistory();

  useEffect(() => {
    clearTimeout(timeout.current);
    timeout.current = (setTimeout(() => {
      setOpenDelayed(isOpen);
    }, 500) as unknown) as number;
    if (!isOpen)
      setTimeout(() => {
        setSearchText('');
      }, 500);
  }, [isOpen]);

  const navCapturer: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    switch (e.key) {
      case 'Escape':
        setIsOpen(false);
        break;
      case 'ArrowUp':
        setSelectedIndex((i) => Math.max(((i + 1 - 1) % (searchResults.length + 2)) - 1, -1)); //Basically, shift up such that -1 index is 0, use modular arithmetic to make it wrap @ len + 2, shift back such that -1 is still -1
        setIsOpen(true);
        e.preventDefault();
        break;
      case 'ArrowDown':
        setSelectedIndex((i) => ((i + 1 + 1) % (searchResults.length + 2)) - 1);
        setIsOpen(true);
        e.preventDefault();
        break;
      case 'Enter':
        if (searchText !== '') {
          if (selectedIndex === -1 || selectedIndex === searchResults.length) {
            history.push(`/search/${searchText}`);
          } else {
            const selected = getByFId(searchResults[selectedIndex].ref || '', [
              ...articles.docs,
              ...chiefArticles.docs,
              ...changelogArticles.docs,
            ]);
            if (!selected) return;

            history.push(`/category/${selected.category}/${selected.fId}`);
          }
          setIsOpen(false);
        } else {
          toasts.error(localizedStrings.search.searchError);
        }
        break;
      default:
        //just typing
        setIsOpen(true);
        break;
    }
  };

  useEffect(() => {
    setSelectedIndex(-1);
  }, [searchText]);

  return (
    <Dropdown
      toggle={() => {
        /**/
      }}
      isOpen={openDelayed && searchResults.length > 0}
      style={{ width: '100%' }}
      className="search-dropdown"
    >
      <DropdownToggle data-toggle="dropdown" tag="div" className="search-input">
        <div
          className={`search-input-parent-stretcher ${isOpen ? 'focused-child' : ''}`}
          style={{ position: 'relative' }}
        >
          <span style={{ position: 'absolute', top: '0.35rem', left: '0.85rem' }}>
            <FontAwesomeIcon icon={faSearch} />
          </span>
          <Input
            style={{ paddingLeft: '2rem' }}
            onFocus={() => {
              setIsOpen(true);
            }}
            onBlurCapture={() => {
              setIsOpen(false);
            }}
            onChange={({ target: { value } }) => setSearchText(value)}
            onKeyDownCapture={navCapturer}
            value={searchText}
            placeholder={localizedStrings.global.search}
          />
        </div>
      </DropdownToggle>
      <DropdownMenu style={{ minWidth: '100%', opacity: isOpen ? '1' : '0' }}>
        <DropdownItem header style={{ paddingBottom: '15px', fontWeight: 'bold' }}>
          {localizedStrings.search.searchSuggest}
        </DropdownItem>
        {searchResults.map((r, i) => (
          <DropdownItem
            className="fast-search-result d-flex justify-content-between text-wrap"
            key={i}
            active={selectedIndex === i}
            onMouseEnter={() => setSelectedIndex(i)}
            tag="div"
          >
            <InlineSearchResult key={r.ref} id={r.ref} />
          </DropdownItem>
        ))}

        <DropdownItem
          active={selectedIndex === searchResults.length}
          tag="div"
          onMouseEnter={() => setSelectedIndex(searchResults.length)}
        >
          <Link style={{ display: 'block' }} to={`/search/${searchText}`}>
            <FontAwesomeIcon icon={faSearch} /> {localizedStrings.search.seeAll} "{searchText}"
          </Link>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

const InlineSearchResult = ({ id }: { id: string }) => {
  const article = useArticle(id);

  if (!article) {
    return <>{localizedStrings.error.error}</>;
  }

  return (
    <Link style={{ display: 'block', width: '100%' }} to={`/category/${article.category}/${article.fId}`}>
      {article.name}
    </Link>
  );
};

export const DraftModeSwitcher = (): ReactElement => {
  const auth = useAuth();
  const [isDraft, setIsDraft] = useIsDraft();
  const online = useOnline();

  useEffect(() => {
    if (!online) setIsDraft(false);
    // eslint-disable-next-line
  }, [online]);

  if (!auth.isAdmin || !online) return <></>;
  return (
    <IconButton
      onClick={() => setIsDraft(!isDraft)}
      style={{ whiteSpace: 'nowrap' }}
      icon={isDraft ? faEye : faEdit}
      text={isDraft ? localizedStrings.publish.publishMode : localizedStrings.global.edit}
    />
  );
};

enum PublishState {
  IDLE,
  WAIT_CONFIRM,
  IN_FLIGHT,
  ERROR,
}
export const PublishButton = (): ReactElement => {
  const [isDraft] = useIsDraft();
  const auth = useAuth();
  const firestore = useFirestore();
  const project = useConcreteProject();
  const [publishState, setPublishState] = useState(PublishState.IDLE);
  const [report, setReport] = useState<PublishResult>();
  const aActions = appActions(firestore, project.id);

  const startPublish = () => {
    setPublishState(PublishState.WAIT_CONFIRM);
  };
  const publish = (publishMessage?: string) => {
    setPublishState(PublishState.IN_FLIGHT);
    toasts.info(localizedStrings.publish.start);
    if (!auth.isAdmin || !auth.user?.uid) {
      toasts.error(localizedStrings.auth.notAuthorized);
      return;
    }
    aActions
      .publish(auth.user.uid, publishMessage)
      .then((publishResult) => {
        if (publishResult.publishedSuccessful) {
          toasts.success(localizedStrings.publish.publishOk);
          setPublishState(PublishState.IDLE);
          return;
        }
        setReport(publishResult);
        setPublishState(PublishState.ERROR);
      })
      .catch((e) => {
        toasts.error(localizedStrings.publish.publishFail);
      });
  };
  const cancel = () => {
    setPublishState(PublishState.IDLE);
  };

  if (!auth.isAdmin || !isDraft) return <></>;

  return (
    <>
      <IconButton
        onClick={() => startPublish()}
        style={{ whiteSpace: 'nowrap' }}
        icon={faUpload}
        useCustomSpinner
        isLoading={publishState === PublishState.IN_FLIGHT}
        text={
          publishState === PublishState.IN_FLIGHT
            ? localizedStrings.publish.publishing
            : localizedStrings.publish.publish
        }
      />
      <PublishMessageModal
        onCancel={() => {
          cancel();
        }}
        onPublish={(publishMessage) => {
          publish(publishMessage);
        }}
        isOpen={publishState === PublishState.WAIT_CONFIRM}
      />
      {report && (
        <ConsistencyReportModal
          isOpen={true}
          result={report}
          onDone={() => {
            setPublishState(PublishState.IDLE);
            setReport(undefined);
          }}
        />
      )}
    </>
  );
};

interface ConsistencyReportProps {
  onDone: () => void;
  result: PublishResult;
  isOpen: boolean;
}

const ConsistencyReportModal = ({ result, onDone, isOpen }: ConsistencyReportProps): ReactElement => {
  const articles = useArticlesDraft();
  const categories = useCategories();
  const categoryIdNameMap: Record<string, string> = categories.docs.reduce((acc, cat) => {
    acc[cat.fId] = cat.name;
    return acc;
  }, {});
  const articlesById: Record<string, Article> = articles.docs.reduce((acc, a) => {
    acc[a.fId] = a;
    return acc;
  }, {});

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        <h3>{localizedStrings.publish.publishFail}</h3>
      </ModalHeader>
      <ModalBody>
        <ul style={{ listStyle: 'none' }}>
          {Object.entries(result.consistencyReport as { [key: string]: ConsistencyReportData }).map(
            ([articleId, reportData]) => {
              const article = articlesById[articleId];
              const categoryName = categoryIdNameMap[article.category];

              return (
                <li key={articleId} style={{ paddingBottom: '1rem' }}>
                  <h5>
                    <Link
                      to={`/category/${article.category}/${articleId}`}
                      onClick={() => {
                        onDone();
                      }}
                    >
                      {article.name} ({categoryName})
                    </Link>
                  </h5>
                  {reportData.href.length > 0 && (
                    <>
                      <h6>{localizedStrings.publish.linksWithIssues}</h6>
                      <ul>
                        {reportData.href.map((link) => (
                          <li key={link} style={{ wordBreak: 'break-all' }}>
                            {link}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                  {reportData.images.length > 0 && (
                    <>
                      <h6>{localizedStrings.publish.imagesWithIssues}</h6>
                      <ul>
                        {reportData.images.map((image) => (
                          <li key={image} style={{ wordBreak: 'break-all' }}>
                            {image}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </li>
              );
            },
          )}
        </ul>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            onDone();
          }}
          style={{ whiteSpace: 'nowrap' }}
        >
          {localizedStrings.global.ok}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const UserNav = (): ReactElement => {
  const { user, signOut } = useAuth();
  const history = useHistory();
  if (!user) {
    return (
      <IconButton
        onClick={() => history.push('/sign-in')}
        style={{ whiteSpace: 'nowrap' }}
        icon={faSignOutAlt}
        text={localizedStrings.auth.signInButton}
      />
    );
  } else {
    return (
      <IconButton
        onClick={signOut}
        style={{ whiteSpace: 'nowrap' }}
        icon={faSignIn}
        text={localizedStrings.auth.signOutButton}
        theme="light"
        tooltip={user.displayName || undefined}
      />
    );
  }
};

export const SettingsButton = (): ReactElement => {
  const auth = useAuth();
  const history = useHistory();
  const [draftMode] = useIsDraft();

  if (!auth.isAdmin || !draftMode) return <></>;

  return (
    <IconButton
      onClick={() => {
        history.push('/settings');
      }}
      style={{ whiteSpace: 'nowrap' }}
      icon={faCog}
      text={localizedStrings.settings.settings}
    />
  );
};

export const Header = (): ReactElement => {
  const [fs] = useFullscreen();

  return (
    <header>
      {!fs && (
        <div className="header-top-row">
          <AppLogo />
          <div className="header-top-row-right">
            <SearchInput />
            <div className="header-top-button ">
              <SettingsButton />
              <PublishButton />
              <DraftModeSwitcher />
              <UserNav />
            </div>
          </div>
          <HamburgerMenu />
        </div>
      )}
      <div className="header-top-category-navigation">
        <CategoryNavigation />
      </div>
    </header>
  );
};
