export interface LocalizedStrings {
  auth: {
    signInButton: string;
    signOutButton: string;
    emailTitle: string;
    emailPlaceholder: string;
    logout: string;
    error: {
      unknown: string;
      invalidCredentials: string;
      invalidStaffPassword: string;
    };
    wrongProject: string;
    staffAccount: string;
    adminAccount: string;
    authenticated: string;
    loggedout: string;
    notAuthenticated: string;
    notAuthorized: string;
    resetEmailSent: string;
    resetEmailFail: string;
    passwordTitle: string;
    passwordPlaceholder: string;
    passwordPlaceholderRetype: string;
    passwordChangeSuccess: string;
    passwordChangeFailure: string;
    staffPasswordNotUnique: string;
    passwordRules: {
      adminHeader: string;
      staffHeader: string;
      lowercase: string;
      uppercase: string;
      special: string;
      number: string;
      whitespace: string;
      adminMin: string;
      staffMin: string;
      staffMax: string;
    };
    invalidPasswordFormat: string;
    invalidPasswordRetype: string;
    welcomeBack: string;
    loginToGetAccess: string;
    forgotPasswordClick: string;
    here: string;
    recoveryEmailInfo: string;
    resetPassword: string;
    loginToSee: string;
  };
  error: {
    error: string;
    unknown: string;
    404: string;
  };
  external: {
    externalContent: string;
  };
  global: {
    save: string;
    doNotSave: string;
    cancel: string;
    preview: string;
    view: string;
    edit: string;
    delete: string;
    loading: string;
    startApplication: string;
    searchResult: string;
    search: string;
    allResults: string;
    feedbackSubject: string;
    privacyPolicy: string;
    termsOfUse: string;
    fileFromatError: string;
    print: string;
    create: string;
    clear: string;
    ok: string;
    send: string;
    online: string;
    offline: string;
    clickToReload: string;
    clickToReloadFull: string;
    linkTo: string;
    linkCopied: string;
    insertAnchorlink: string;
    homePage: string;
    feedback: {
      feedback: string;
      feedbackAndPrint: string;
      send: string;
      noFeedbackEmailExist: string;
    };
    fullscreen: string;
    addurl: string;
    iframTooltip: string;
  };
  publish: {
    publish: string;
    publishing: string;
    modalHeader: string;
    modalBody: string;
    start: string;
    publishOk: string;
    publishFail: string;
    applicationMode: string;
    editMode: string;
    publishMode: string;
    defaultPublishMessage: string;
    setPublishMessage: string;
    linksWithIssues: string;
    imagesWithIssues: string;
  };
  settings: {
    settings: string;
    updateOk: string;
    updateFailed: string;
    email: string;
    emailSettings: string;
    addEmailsHint: string;
    categoryAccessHint: string;
    emailAlreadyExist: string;
    staffAccountMng: string;
    staffAccountCategories: string;
    staffAccountCategoriesPlaceholder: string;
    accountMng: string;
    selectAccount: string;
    emptyAccountSearch: string;
    changeAdminPass: string;
    changeStaffPass: string;
    passChangeSuccess: string;
    passChangeFail: string;
    emailError: string;
    projectSettings: string;
    projectNameHint: string;
    projectOrganisationHint: string;
    projectPublisherHint: string;
    handbook: string;
    categories: string;
    accessRoles: string;
    access: string;
    export: {
      create: string;
      delete: string;
      deleteFinished: string;
      download: string;
      exportStarted: string;
      exportFinished: string;
      generating: string;
      hint: string;
      title: string;
      notFound: string;
      failedToDownload: string;
    };
  };
  sorting: {
    articleSort: string;
    sectionSort: string;
    articleEdit: string;
    sectionEdit: string;
    titleForm: string;
    cancelSorting: string;
    saveSorting: string;
    sortingArticlesHasChanged: string;
    sortingSectionsHasChanged: string;
    unsavedChanges: string;
    sortingHasChanged: string;
  };
  article: {
    article: string;
    draftModalHeader: string;
    draftModalBody: string;
    articleSavedOk: string;
    deleteModalHeader: string;
    deleteModalBody: string;
    deleteModalBodyPerma: string;
    deleteOk: string;
    addOk: string;
    sortingSaved: string;
    addNewArticle: string;
    wrongArticleName: string;
    notFoundWarning: string;
    updateOk: string;
    updateFailed: string;
    fileTooLarge: string;
  };
  category: {
    category: string;
    lock: string;
    lockCategoryInputHint: string;
    lockOk: string;
    protectOk: string;
    lockFail: string;
    protectFail: string;
    login: string;
    password: string;
    pinCheckNotValid: string;
    pinAddNotValid: string;
    notFoundWarning: string;
    updated: string;
    updateError: string;
  };
  section: {
    section: string;
    defaultSection: string;
    deleteModalHeader: string;
    deleteModalBody: string;
    deleteModalBodyPerma: string;
    sectionDeleted: string;
    sortingSaved: string;
    addOk: string;
    addError: string;
    deleteOk: string;
    addNewSection: string;
    wrongSectionName: string;
  };
  project: {
    notLoaded: string;
    noStaffAccount: string;
  };
  forms: {
    placeHolders: {
      createNewSection: string;
      sectionName: string;
      articleName: string;
    };
    messages: {
      valueToShort: string;
    };
  };
  search: {
    relevance: string;
    alphabetical: string;
    up: string;
    down: string;
    allResults: string;
    seeAll: string;
    searchSuggest: string;
    searchError: string;
    pageNumberError: string;
    resultError: string;
  };
}

export const en: LocalizedStrings = {
  auth: {
    signInButton: 'Sign in',
    signOutButton: 'Sign out',
    emailTitle: 'Email',
    emailPlaceholder: 'your@email.com',
    passwordTitle: 'Password',
    passwordPlaceholder: 'Password',
    passwordPlaceholderRetype: 'Retype password',
    logout: 'Logout',
    error: {
      unknown: 'Unknown error',
      invalidCredentials: 'Invalid email/password',
      invalidStaffPassword: 'Invalid staff account password',
    },
    wrongProject: 'You cannot login into this project!',
    staffAccount: 'Staff',
    adminAccount: 'Administrator',
    authenticated: 'You are logged in as',
    loggedout: 'You have been logged out',
    notAuthenticated: 'You are not logged in',
    notAuthorized: 'You are not authorized',
    passwordChangeFailure: 'Failed to update password',
    passwordChangeSuccess: 'Password updated',
    invalidPasswordFormat: 'Password format is wrong!',
    invalidPasswordRetype: 'Passwords do not match!',
    staffPasswordNotUnique: 'Password for staff account must be unique',
    welcomeBack: 'Welcome back',
    loginToGetAccess: 'Log in to access the platform',
    forgotPasswordClick: 'Forgot password? Click',
    here: 'here',
    resetEmailSent: 'Email sent. Check your inbox',
    resetEmailFail: 'Unknown problem. Email not sent, contact support.',
    recoveryEmailInfo:
      'Input the e-mail you use to sign in to the platform to get a password recovery e-mail. If the e-mail does not arrive or if you have other questions, contact Softwerk support',
    resetPassword: 'Reset password',
    loginToSee: 'Log in to access the content',
    passwordRules: {
      adminHeader: 'Password Admin account',
      staffHeader: 'Password Staff account',
      lowercase: 'At least one lowercase letter',
      uppercase: 'At least one uppercase letter',
      special: 'At least one special character [?,!,$,...]',
      number: 'At least one number',
      whitespace: 'No whitespace',
      adminMin: 'Minimum 8 characters long',
      staffMin: 'Minimum 6 characters',
      staffMax: 'Maximum 20 characters',
    },
  },
  error: {
    error: 'Error',
    unknown: 'Something went wrong!',
    404: 'Content not found',
  },
  external: {
    externalContent: 'External Content',
  },
  global: {
    save: 'Save',
    doNotSave: 'Do not save',
    cancel: 'Cancel',
    edit: 'Edit',
    preview: 'Preview',
    view: 'View',
    delete: 'Delete',
    loading: 'Loading...',
    startApplication: 'Start Application...',
    searchResult: 'Search Result',
    search: 'Search',
    allResults: 'All results for',
    feedbackSubject: 'A new feedback submitted on an article',
    privacyPolicy: 'Privacy Policy',
    termsOfUse: 'Legal documents',
    fileFromatError: 'File extention is not supported',
    print: 'Print',
    create: 'Create',
    clear: 'Clear',
    ok: 'OK',
    send: 'Send',
    online: 'You are online',
    offline: 'You are offline. Some functionality is limited.',
    clickToReload: 'Reload component',
    clickToReloadFull: 'Reload the page',
    linkTo: 'Link to',
    linkCopied: 'The link has been copied to your clipboard',
    insertAnchorlink: 'Insert anchor link',
    homePage: 'Home page',
    fullscreen: 'Fullscreen',
    feedback: {
      feedback: 'Feedback',
      feedbackAndPrint: 'Feedback & Print',
      send: 'Send feedback',
      noFeedbackEmailExist: 'No feedback email has been registered',
    },
    addurl: 'Embed external content',
    iframTooltip:
      'You can enter your value in pixels or percentages.Please write % or px after the value. Default value:',
  },
  publish: {
    publish: 'Publish',
    publishing: 'Publishing',
    modalHeader: 'Publish',
    modalBody: 'Are you sure that you want to publish the changes?',
    start: 'Publishing process started',
    publishOk: 'Publishing done',
    publishFail: 'Publishing failed',
    applicationMode: 'Application mode',
    editMode: 'Edit mode',
    publishMode: 'Released',
    defaultPublishMessage: 'A new content has been published.',
    setPublishMessage: 'Set publish message',
    linksWithIssues: 'Links with issues',
    imagesWithIssues: 'Images with issues',
  },
  settings: {
    settings: 'Settings',
    email: 'Feedback Email Addresses',
    emailSettings: 'Email Settings',
    addEmailsHint: 'Add emails to feedback emails.',
    categoryAccessHint:
      'Access to locked categories must be given on a per-account basis. Unlocked categories can be accessd by anyone.',
    updateOk: 'Changes are saved',
    updateFailed: 'Saving changes failed',
    emailAlreadyExist: 'Email Already exist',
    staffAccountMng: 'Staff accounts',
    staffAccountCategories: 'Change access to locked categories',
    staffAccountCategoriesPlaceholder: 'All categories are unlocked!',
    accountMng: 'Account Management',
    selectAccount: 'Select an account to edit',
    emptyAccountSearch: 'No results!',
    changeAdminPass: 'Change admin account password',
    changeStaffPass: 'Change staff account password',
    passChangeSuccess: 'Password change succeeded!',
    passChangeFail: 'Changing password failed',
    emailError: 'An error in email!',
    export: {
      create: 'Create new export',
      delete: 'Delete export',
      deleteFinished: 'Export deleted',
      download: 'Download export',
      exportFinished: 'Export generated!',
      exportStarted: 'Initiating export...',
      generating: 'Generating export...',
      hint:
        'Export an offline-only version of web app containing the latest published content. The latest export is stored and can be downloaded multiple times.',
      title: 'Export',
      notFound: 'No export has been made yet',
      failedToDownload: 'Failed to download the zip file!',
    },
    projectSettings: 'Project Settings',
    projectNameHint: 'Name',
    projectOrganisationHint: 'Organisation',
    projectPublisherHint: 'Publisher',
    handbook: 'Handbook',
    categories: 'Categories',
    accessRoles: 'Access Roles',
    access: 'Manage access to categories',
  },
  sorting: {
    articleSort: 'Articles',
    sectionSort: 'Sections',
    titleForm: 'Article & Section sorting',
    articleEdit: 'Edit Articles',
    sectionEdit: 'Edit Sections',
    cancelSorting: 'Cancel Sorting',
    saveSorting: 'Save Sorting',
    sortingArticlesHasChanged: ' Sorting has changed',
    sortingSectionsHasChanged: 'Sorting has changed',
    unsavedChanges: 'You have unsaved changes',
    sortingHasChanged: 'Sorting  has changed',
  },
  article: {
    article: 'Article',
    draftModalHeader: 'Not saved content',
    draftModalBody: 'Edited content is not saved.',
    articleSavedOk: 'Article is saved',
    deleteModalHeader: 'Delete article',
    deleteModalBody: 'Are you sure you want to delete this article?',
    deleteModalBodyPerma: 'Are you sure you want to delete this article permanently?',
    deleteOk: 'Article deleted!',
    addOk: 'Article added!',
    sortingSaved: 'Article sorting Saved',
    addNewArticle: 'Add new article',
    wrongArticleName: 'Wrong article name',
    notFoundWarning: 'Article not found!',
    updateOk: 'The article has been updated',
    updateFailed: 'The article update failed',
    fileTooLarge: 'The file is too large. Max 10MB',
  },
  category: {
    category: 'Category',
    lock: 'Pin lock',
    lockCategoryInputHint:
      'Set pin code to protect the category, pin should be numbers between 4 and 6 long.' +
      'to remove the pin provide empty value',
    lockOk: 'Category pin has been set.',
    lockFail: 'Error while setting category pin.',
    login: 'View Content',
    password: 'Enter pin code',
    pinCheckNotValid: 'Pin code is not valid!',
    pinAddNotValid:
      'Provided pin is not valid should be between 4 -6 and only numbers. To remove the pin provide empty value',
    protectOk: 'Category protection changed',
    protectFail: 'Changing Category Protection Failed',
    notFoundWarning: 'Category not found!',
    updated: 'Category updated',
    updateError: 'Error when updating category',
  },
  section: {
    section: 'Section',
    defaultSection: 'No section',
    deleteModalHeader: 'Delete section',
    deleteModalBody: 'Are you sure do you want to delete this section?',
    deleteModalBodyPerma: 'Are you sure do you want to delete this section permanently?',
    sectionDeleted: 'Section is deleted!',
    sortingSaved: 'Section Sorting saved',
    addOk: 'Section added',
    addError: 'Adding section failed',
    deleteOk: 'Section deleted',
    wrongSectionName: 'Invalid section name',
    addNewSection: 'Add new section',
  },
  project: {
    notLoaded: 'The project not loaded correctly  or not found.',
    noStaffAccount: 'This project has NO staff account',
  },
  forms: {
    placeHolders: { createNewSection: 'Create new section', sectionName: 'Section Name', articleName: 'Article Name' },
    messages: { valueToShort: 'The value you have entered is to short' },
  },
  search: {
    relevance: 'Relevans',
    alphabetical: 'Alphabetical',
    up: 'Rising',
    down: 'Falling',
    allResults: 'All results',
    seeAll: 'See all results for',
    searchSuggest: 'Search suggestions',
    searchError: 'You did not enter any search terms',
    pageNumberError: 'The number entered is incorrect',
    resultError: 'No article were found matching your selection.',
  },
};

export const sv: LocalizedStrings = {
  auth: {
    signInButton: 'Logga in',
    signOutButton: 'Logga ut',
    emailTitle: 'E-post',
    emailPlaceholder: 'example@domain.com',
    passwordTitle: 'Lösenord',
    passwordPlaceholder: 'Lösenord',
    passwordPlaceholderRetype: 'Upprepa lösenord',
    logout: 'Logga ut',
    error: {
      unknown: 'Okänt fel',
      invalidCredentials: 'Ogiltigt användarnamn eller lösenord',
      invalidStaffPassword: 'Ogiltigt lösenord för personalkonto',
    },
    wrongProject: 'Du har inte behörighet till det här projektet.',
    staffAccount: 'Personal',
    adminAccount: 'Administratör',
    authenticated: 'Du är inloggad som',
    loggedout: 'Du har loggats ut',
    notAuthenticated: 'Du är inte inloggad',
    notAuthorized: 'Du har inte rättigheter till det här',
    passwordChangeSuccess: 'Lösenordet uppdaterat',
    passwordChangeFailure: 'Lösenordsuppdateringen misslyckades!',
    invalidPasswordFormat: 'Lösenordet är inte korrekt formaterat',
    invalidPasswordRetype: 'Lösenorden matchar inte',
    staffPasswordNotUnique: 'Lösenord för personalkonton måste vara unika',
    welcomeBack: 'Välkommen tillbaka',
    loginToGetAccess: 'Logga in för att få tillgång till plattformen',
    forgotPasswordClick: 'Glömt ditt lösenord? Klicka',
    here: 'här',
    resetEmailSent: 'Email skickat!',
    resetEmailFail: 'Fel vid emailutskick. Kontakta support.',
    recoveryEmailInfo:
      'Skriv in e-postadressen du använder för att logga in på plattformen. Därefter får du ett mail för att återställa ditt lösenord. Om mailet inte kommer eller du har andra frågor, kontakta Softwerks support',
    resetPassword: 'Återställ lösenord',
    loginToSee: 'Logga in för att få tillgång till innehållet',
    passwordRules: {
      adminHeader: 'Lösenord Administratörskonto',
      staffHeader: 'Lösenord Personalkonto',
      lowercase: 'Minst en liten bokstav',
      uppercase: 'Minst en stor bokstav',
      special: 'Minst ett specialtecken [?,!,$,...]',
      number: 'Minst ett nummer',
      whitespace: 'Inget vitt utrymme',
      adminMin: 'Minst 8 tecken långt',
      staffMin: 'Minst 6 tecken',
      staffMax: 'Max 20 tecken',
    },
  },
  error: {
    error: 'Fel',
    unknown: 'Något gick snett!',
    404: 'Innehållet hittas inte',
  },
  external: {
    externalContent: 'Externt Innehåll',
  },
  global: {
    save: 'Spara',
    doNotSave: 'Spara inte',
    cancel: 'Avbryt',
    edit: 'Redigera',
    view: 'Visa',
    preview: 'Förhandsvisa',
    delete: 'Ta bort',
    loading: 'Laddar...',
    startApplication: 'Startar Applikation...',
    searchResult: 'sökresultat',
    search: 'Sök',
    allResults: 'Alla resultat för',
    feedbackSubject: 'Ny feedback skickad',
    privacyPolicy: 'Integritetspolicy',
    termsOfUse: 'Juridiska dokument',
    fileFromatError: 'Filformat stöds inte',
    print: 'Skriv ut',
    create: 'Skapa',
    clear: 'Rensa',
    ok: 'OK',
    send: 'Skicka',
    online: 'Du är online',
    offline: 'Du är offline. Viss funktionalitet är begränsad.',
    clickToReload: 'Ladda om komponent',
    clickToReloadFull: 'Ladda om sidan',
    linkTo: 'Länk till',
    linkCopied: 'Länken har kopierats!',
    insertAnchorlink: 'Infoga ankarlänk',
    homePage: 'Hemsida',
    fullscreen: 'Fullskärm',
    feedback: {
      feedback: 'Feedback',
      feedbackAndPrint: 'Feedback & Skriv ut',
      send: 'Skicka feedback',
      noFeedbackEmailExist: 'Ingen feedback-e-post är registrerad',
    },
    addurl: 'Bädda in externt innehåll',
    iframTooltip: 'Du kan ange ditt värde i pixlar eller procent. Skriv % eller px efter värdet. Standardvärde:',
  },
  publish: {
    publish: 'Publicera',
    publishing: 'Publicerar',
    modalHeader: 'Publicera',
    modalBody: 'Är du säker på att du vill publicera ändringarna?',
    start: 'Publicering påbörjad!',
    publishOk: 'Publicering klar!',
    publishFail: 'Publicering misslyckades',
    applicationMode: 'Application mode',
    editMode: 'Redigeringsläge',
    publishMode: 'Publiceringsläge',
    defaultPublishMessage: 'Nytt innehåll har publicerats',
    setPublishMessage: 'Publiceringsmeddelande',
    linksWithIssues: 'Länkar med problem',
    imagesWithIssues: 'Bilder med problem',
  },
  settings: {
    settings: 'Inställningar',
    email: 'E-post-adresser för feedback',
    emailSettings: 'E-post inställningar',
    addEmailsHint: 'Lägg till e-post som feedback-mail',
    categoryAccessHint: 'Åtkomst för låsta kategorier måste ges per konto. Upplåsta kategorier kan ses av allmänheten.',
    updateOk: 'Ändringen har sparats',
    updateFailed: 'Ändringen misslyckades',
    emailAlreadyExist: 'E-postadressen finns redan',
    staffAccountMng: 'Personalkonton',
    staffAccountCategories: 'Ändra åtkomst till låsta kategorier',
    staffAccountCategoriesPlaceholder: 'Alla kategorier är upplåsta!',
    accountMng: 'Ditt konto',
    selectAccount: 'Välj ett konto att redigera',
    emptyAccountSearch: 'Inga resultat!',
    changeAdminPass: 'Ändra lösenord för administratörkonto',
    changeStaffPass: 'Ändra lösenord för personalkonto',
    passChangeSuccess: 'Lösenordet har ändrats!',
    passChangeFail: 'Byte av lösenord misslyckades',
    emailError: 'Fel i e-postadressen',
    export: {
      create: 'Skapa ny export',
      download: 'Ladda ner export',
      delete: 'Radera export',
      deleteFinished: 'Export raderad',
      exportStarted: 'Påbörjar export...',
      exportFinished: 'Exporten har skapats!',
      generating: 'Skapar export...',
      hint:
        'Exportera den senaste publicerade versionen av din webb-app som går att använda helt offline. Senaste exporten lagras och kan laddas ner flera gånger.',
      title: 'Exportera',
      notFound: 'Ingen export har skapats ännu',
      failedToDownload: 'Nedladdningen av zip-filen misslyckades!',
    },
    projectSettings: 'Namnval',
    projectNameHint: 'Applikationens namn',
    projectPublisherHint: 'Innehålls- och publiceringsansvarig',
    projectOrganisationHint: 'Organisation',
    handbook: 'Handbok',
    categories: 'Kategorier',
    accessRoles: 'Åtkomstroller',
    access: 'Hantera åtkomst till kategorier',
  },
  sorting: {
    articleSort: 'Artiklar',
    sectionSort: 'Sektioner',
    titleForm: 'Artiklar&Sektioner sortering',
    articleEdit: 'Redigera artiklar',
    sectionEdit: 'Redigera sektioner',
    cancelSorting: 'Avbryt',
    saveSorting: 'Spara',
    sortingArticlesHasChanged: 'Sortering har ändrats!',
    sortingSectionsHasChanged: 'Sortering har ändrats!',
    unsavedChanges: 'Du har osparade ändringar i sorteringen',
    sortingHasChanged: 'Sortering har ändrats',
  },
  article: {
    article: 'Artikel',
    draftModalHeader: 'Innehåller osparat innehåll',
    draftModalBody: 'Redigerat innehåll har inte sparats.',
    articleSavedOk: 'Artikeln sparad',
    deleteModalHeader: 'Radera artikel',
    deleteModalBody: 'Vill du verkligen radera artikeln?',
    deleteModalBodyPerma: 'Vill du verkligen radera artikeln permanent?',
    deleteOk: 'Artikel raderad',
    addOk: 'Artikel har lagts till',
    sortingSaved: 'Artikelsortering sparad',
    addNewArticle: ' Artikel',
    wrongArticleName: 'Artikelnamnet är inte giltigt',
    notFoundWarning: 'Artikeln hittades inte!',
    updateOk: 'Artikeln har uppdaterats',
    updateFailed: 'Artikeluppdateringen misslyckades',
    fileTooLarge: 'Filen är för stor. Max 10MB',
  },
  category: {
    category: 'Kategori',
    lock: 'Pin lock',
    lockCategoryInputHint:
      'Set pin code to protect the category, pin should be numbers between 4 and 6 long.' +
      'to remove the pin provide empty value',
    lockOk: 'Category pin has been set.',
    lockFail: 'Error while setting category pin.',
    login: 'View Content',
    password: 'Enter pin code',
    pinCheckNotValid: 'Pin code is not valid!',
    pinAddNotValid:
      'Provided pin is not valid should be between 4 -6 and only numbers. To remove the pin provide empty value',
    protectOk: 'Behörighet för kategori uppdaterad',
    protectFail: 'uppdatering av kategoribehörighet, misslyckades',
    notFoundWarning: 'Kategori hittades inte!',
    updated: 'Kategori uppdaterad',
    updateError: 'Fel i kategoriuppdatering',
  },
  section: {
    section: 'Sektion',
    defaultSection: 'Ingen sektion',
    deleteModalHeader: 'Radera Sektion',
    deleteModalBody: 'Vill du verkligen radera sektionen?',
    deleteModalBodyPerma: 'Vill du verkligen radera sektionen permanent?',
    sectionDeleted: 'Sektionen raderad',
    sortingSaved: 'Sektionssortering sparad',
    addOk: 'Sektion har lagts till',
    addError: 'Kunde inte lägga till sektion',
    deleteOk: 'Sektion raderad',
    addNewSection: ' Sektion',
    wrongSectionName: 'Sektionsnamnet är inte giltigt',
  },
  project: {
    notLoaded: 'Projektet laddades inte in korrekt eller hittades inte',
    noStaffAccount: 'Projektet här ingen personalkonto',
  },
  forms: {
    placeHolders: { createNewSection: 'Lägg till ny sektion', sectionName: 'Sektionsnamn', articleName: 'Artikelnamn' },
    messages: { valueToShort: 'The value you have entered is to short' },
  },
  search: {
    relevance: 'Relevans',
    alphabetical: 'Alfabetiskt',
    up: 'Stigande',
    down: 'Fallande',
    allResults: 'Alla resultat',
    seeAll: 'Se alla resultat för',
    searchSuggest: 'Sökförslag',
    searchError: 'Du har inte angett några söktermer',
    pageNumberError: 'Det angivna numret är felaktigt',
    resultError: 'Ingen artikel hittades som matchade ditt val.',
  },
};

export const localizedStrings: LocalizedStrings = sv;
