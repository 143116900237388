import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { usePlatform } from '../ContextProviders/AppContext';
import './PlatformContentFrame.scss';
import { useLocation } from 'react-router-dom';
import { toasts } from '../../shared';
import { localizedStrings } from '../../localizedStrings';

interface IFrameData {
  body: string;
  scrolling?: string;
  header?: boolean;
  bodyClass?: string;
}
const template = (
  scriptSrcs: string[],
  styleHrefs: string[],
  body: string,
  injectHeader: boolean,
  bodyClass?: string,
): string => `
<!DOCTYPE HTML>
<html>
<head>
  <base target="_top">
  ${scriptSrcs.map((src) => `<script type="text/javascript" src="${src}"></script>`).join('\n')}
  ${styleHrefs.map((src) => `<link rel="stylesheet" type="text/css" href="${src}">`).join('\n')}
  <style>
  body:not(.edit-mode) [data-anchor-link] {
    visibility:hidden;
    width:0;
    pointer-events:none;
    overflow:hidden;
    display:inline-block;
  }
  [data-anchor-link] {
    cursor: pointer;
  }
  </style>
</head>
<body class="fr-view ${bodyClass ?? ''}">
    ${body}
    ${
      injectHeader
        ? `<script type="text/javascript">
    if (typeof setupArticle === "function") {
      setupArticle();
    }
    </script>`
        : ''
    }
</body>
</html>
`;

export const PlatformContentFrame = ({
  body,
  scrolling = 'yes',
  header = true,
  bodyClass,
}: IFrameData): ReactElement => {
  const platform = usePlatform();
  const { projectJS, projectCSS, platformCSS, platformJS, froalaCSS } = platform.doc;
  const [element, setElement] = useState<HTMLIFrameElement>();
  const history = useHistory();
  const location = useLocation();

  const linkCallback = useCallback((ev: Event) => {
    const target = ev.target as HTMLElement;

    const anchorAttribute = target.getAttribute('data-anchor-link');
    if (anchorAttribute) {
      void navigator.clipboard.writeText(anchorAttribute).then(() => {
        toasts.info(localizedStrings.global.linkCopied);
      });
    }

    const link = target.closest('a')?.getAttributeNS(null, 'href');
    if (!link) return;
    ev.preventDefault();

    const url = new URL(link, window.origin);

    // scroll to hash on current page
    const locationId = url.pathname.substring(url.pathname.lastIndexOf('/') + 1);
    if (url.hash && document.location.pathname.includes(locationId)) {
      scrollToAnchorLink(url.hash);
      return;
    }

    if (document.location.hostname === url.hostname) {
      if (url.hash) history.push(url.pathname + url.hash);
      else history.push(url.pathname);
    } else window.open(url.href);
    // eslint-disable-next-line
  }, []);

  const scrollToAnchorLink = (targetId: string) => {
    const iframeContent = document.querySelector('iframe');
    const element = iframeContent?.contentDocument?.getElementById(targetId.replace('#', ''));

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (element) {
      if (document.readyState === 'complete') {
        element.contentDocument?.addEventListener('click', linkCallback, false);
      }
      element.onload = () => {
        element.contentDocument?.addEventListener('click', linkCallback, false);
        if (location.hash) {
          scrollToAnchorLink(location.hash);
        }
      };

      return () => {
        element.contentDocument?.removeEventListener('click', linkCallback, false);
      };
    }
    return () => {
      /**/
    };
    // eslint-disable-next-line
  }, [element]);

  if (!platform.doc) return <></>;

  return (
    <iframe
      title="platform-content-iframe"
      id="platform-content-iframe"
      ref={(r) => {
        if (r) setElement(r);
      }}
      scrolling={scrolling}
      srcDoc={template([platformJS, projectJS], [projectCSS, platformCSS, froalaCSS], body, header, bodyClass)}
    ></iframe>
  );
};
