import { ReactElement, useEffect, useState } from 'react';
import { PopoverHeader, UncontrolledPopover } from 'reactstrap';
import { useOnline } from '../../Hooks/useOnline';
import { localizedStrings } from '../../localizedStrings';

export const OfflineIndicator = ({
  online: onlineElement,
  offline: offlineElement,
}: {
  online: ReactElement;
  offline: ReactElement;
}): ReactElement => {
  const online = useOnline();
  const [open, setOpen] = useState(false);
  const [element, setElement] = useState<HTMLElement | null>();

  useEffect(() => {
    if (!online) setOpen(true);
    else setOpen(false);
  }, [online]);

  return (
    <>
      <button
        style={{ background: 'none', border: '0', padding: 0, margin: 0 }}
        ref={(r) => setElement(r)}
        id="OnlineFocus"
      >
        {online ? onlineElement : offlineElement}
      </button>
      {element && (
        <UncontrolledPopover
          placement="bottom"
          target={element}
          isOpen={open}
          trigger="click"
          toggle={() => setOpen((o) => !o)}
        >
          <PopoverHeader>{online ? localizedStrings.global.online : localizedStrings.global.offline}</PopoverHeader>
        </UncontrolledPopover>
      )}
    </>
  );
};
