export default [
  { to: 'behandlingsriktlinjerVuxen', title: 'Behandlingsriktlinjer Vuxen' },
  { to: 'behandlingsriktlinjerBarn', title: 'Behandlingsriktlinjer Barn' },
  { to: 'RGS', title: 'RGS' },
  { to: 'retts', title: 'Retts' },
  { to: 'calculator', title: 'Dosberäknare' },
  { to: 'preparat', title: 'Preparathandbok' },
  { to: 'information', title: 'Information' },
  { to: 'changelog', title: 'Nyheter och Uppdateringar' },
  { to: 'felanmalan', title: 'Felanmälan' },
];
