import { ReactElement, useEffect } from 'react';
import { localizedStrings } from '../../../localizedStrings';
import { useProjectConfig } from '../../ContextProviders/AppContext';
import { PageWithSidebar } from '../PageTypes';
import { SettingsSidebar } from './SettingsSidebar';

export const Handbook = (): ReactElement => {
  const projectConfig = useProjectConfig();

  useEffect(() => {
    document.title = `${projectConfig.doc.name} | ${localizedStrings.settings.handbook}`;
  }, [projectConfig.doc?.name]);

  return (
    <PageWithSidebar>
      <SettingsSidebar />
      <main className="padded-container">
        <div className="settings-page">
          <h1>{localizedStrings.settings.handbook}</h1>
          <hr />
          <iframe
            title="handbook"
            src={'https://storage.googleapis.com/infosynk-static-file-directory/Handbok%20infosynk.pdf'}
          />
        </div>
      </main>
    </PageWithSidebar>
  );
};
