import { Children, cloneElement, Component } from 'react';

import './FabStyles.scss';

// eslint-disable-next-line
const AB = (p: any) => (
  <button type="button" {...p} className="rtf--ab">
    {p.children}
  </button>
);

// eslint-disable-next-line
export const MB = (p: any) => (
  <button type="button" className="rtf--mb" {...p}>
    {p.children}
  </button>
);

interface Props {
  // eslint-disable-next-line
  event: any;
  // eslint-disable-next-line
  position: any;
  // eslint-disable-next-line
  icon: any;
  // eslint-disable-next-line
  mainButtonStyles: any;
  // eslint-disable-next-line
  actionButtonStyles?: any;
}

interface State {
  open: boolean;
}

class Fab extends Component<Props, State> {
  static defaultProps = {
    position: { bottom: 0, right: 0 },
    event: 'hover',
  };

  state = { open: false };

  open = (): false | void => this.setState({ open: true });

  close = (): void => this.setState({ open: false });

  toggle = (): false | void => (this.state.open ? this.close() : this.open());

  // eslint-disable-next-line
  actionOnClick = (userFunc: any) => {
    this.setState({ open: false }, () => {
      // Hack to allow the FAB to close before the user event fires
      setTimeout(() => {
        userFunc();
      }, 1);
    });
  };

  rc() {
    const { children: c, position: p } = this.props;
    const { open } = this.state;
    const count = Children.count(c);

    if (count > 6) {
      console.warn('react-tiny-fab only supports up to 6 action buttons');
    }

    return (
      Children.toArray(c)
        .filter((ch) => ch !== undefined)
        // eslint-disable-next-line
        .map((ch: any, i) => (
          <li
            className={'rtf--ab__c'}
            style={{
              transitionDelay: open ? `${0.05 * (i + 1)}s` : `${count * 0.05 - 0.05 * i}s`,
              transform: `translate(50%, ${'top' in p ? '' : '-'}${60 * (i + 1)}px) scale(${open ? 1 : 0})`,
            }}
            key={i}
          >
            {cloneElement(ch, {
              'data-testid': `action-button-${i}`,
              'aria-label': ch.props.text || `Menu button ${i + 1}`,
              'aria-hidden': !open,
              ...ch.props,
              /* onClick: () => this.actionOnClick(ch.props.onClick), */
            })}
            {ch.props.text && (
              <span className={'right' in p ? 'right' : ''} aria-hidden={!open}>
                {ch.props.text}
              </span>
            )}
          </li>
        ))
    );
  }

  render() {
    const { position, icon, mainButtonStyles } = this.props;
    return (
      <ul
        onClick={this.toggle}
        className={`rtf ${this.state.open ? 'open' : 'closed'}`}
        data-testid="fab"
        style={position}
      >
        <li className="rtf--mb__c">
          <MB style={mainButtonStyles} data-testid="main-button" role="button" aria-label="Floating menu" tabIndex="0">
            {icon}
          </MB>
          <ul>{this.rc()}</ul>
        </li>
      </ul>
    );
  }
}

export { Fab, AB as Action };
export interface Components {
  position: {
    bottom?: number;
    left?: number;
    top?: number;
    right?: number;
  };
  event: string;
  mainButtonStyles: {
    backgroundColor: string;
    color?: string;
  };
  actionButtonStyles: {
    backgroundColor: string;
    color?: string;
  };
}
